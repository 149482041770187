import { securityApiClient } from "./clients";
import {
  IntuneEnrolledDevicesResponse,
  NoncompliantDevicesResponse,
  RiskySigninsResponse,
  RiskyUsersResponse,
  SecureScoreCategorisedResponse,
  SecureScoreHistoryResponse,
  SecureScoreResponse,
  StaleDevicesResponse,
  UnmanagedDevicesResponse,
  UsersResponse,
} from "types/startPageApi.types";

export const getSecureScore = (contractId: string) =>
  securityApiClient.request<SecureScoreResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/security/secure-score`,
  });

export const getStaleDevices = (contractId: string) =>
  securityApiClient.request<StaleDevicesResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/security/stale-devices`,
  });

export const getNoncompliantDevices = (contractId: string) =>
  securityApiClient.request<NoncompliantDevicesResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/security/noncompliant-devices`,
  });

export const getUnmanagedDevices = (contractId: string) =>
  securityApiClient.request<UnmanagedDevicesResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/security/unmanaged-devices`,
  });

export const getUsers = (contractId: string) =>
  securityApiClient.request<UsersResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/security/users`,
  });

export const getSecureScoreHistory = (contractId: string) =>
  securityApiClient.request<SecureScoreHistoryResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/security/secure-score-history`,
  });

export const getSecureScoreCategorised = (contractId: string) =>
  securityApiClient.request<SecureScoreCategorisedResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/security/secure-score-categorised`,
  });

export const getIntuneEnrolledDevices = (contractId: string) =>
  securityApiClient.request<IntuneEnrolledDevicesResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/security/intune-enrolled-devices`,
  });

export const getRiskySignins = (contractId: string) =>
  securityApiClient.request<RiskySigninsResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/security/risky-signins`,
  });

export const getRiskyUsers = (contractId: string) =>
  securityApiClient.request<RiskyUsersResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/security/risky-users`,
  });
