import { ContractActions, ContractsStateSlice } from "./contracts.types";

const initState: ContractsStateSlice = {
  SS: [],
  SE: [],
  SD: [],
  selectedContract: null,
};

export default function contractReducer(
  state: ContractsStateSlice = initState,
  action: ContractActions
): ContractsStateSlice {
  switch (action.type) {
    case "SET_CONTRACTS_LIST":
      return {
        ...state,
        SS: action.payload.filter((contract) => contract.entityType === "SS"),
        SE: action.payload.filter((contract) => contract.entityType === "SE"),
        SD: action.payload.filter((contract) => contract.entityType === "SD"),
        selectedContract: state.selectedContract,
      };
    case "SELECT_CONTRACT":
      return {
        ...state,
        selectedContract: action.payload,
      };
    default:
      return state;
  }
}
