import { combineReducers } from "redux";
import { reducer as app } from "./app";
import { reducer as contracts } from "./contracts";
import { reducer as startPage } from "./startPage";
import { reducer as proactiveSecurityPage } from "./proactiveSecurityPage";
import { reducer as detectAndResponsePage } from "./detectAndResponsePage";
import { reducer as notification } from "./notification";

const rootReducer = combineReducers({
  app,
  contracts,
  startPage,
  proactiveSecurityPage,
  detectAndResponsePage,
  notification,
});

export default rootReducer;
