import { UserPermissionObjectInfo } from "@skyportal/auth-web-client";
import { AppThunk } from "store";
import { authClient } from "api/clients";
import * as appActions from "./app.actions";
import * as contractActions from "../contracts/contracts.actions";
import config from "config";
import { sortCustomersByParentName } from "utils/sort";

export enum SecurityContract {
  SS = "SS",
  SE = "SE",
  SD = "SD",
}
export const securityContractTypes: SecurityContract[] = [
  SecurityContract.SS,
  SecurityContract.SE,
  SecurityContract.SD,
];

export const getUserInfo = (): AppThunk => async (dispatch) => {
  try {
    const userProfile = await authClient.getUserProfile();
    const securityContracts = sortCustomersByParentName(
      userProfile.permissions.entity
        .filter((contract) => securityContractTypes.includes(contract.entityType as SecurityContract))
        .map((contract) => {
          const parentObject = userProfile.permissions[contract.parentType as string]?.find(
            (item) => item.id === contract.parentId
          );
          const parentName = parentObject?.name ?? "Noname";
          return { ...contract, parentName } as UserPermissionObjectInfo;
        })
    );

    if (!securityContracts.length) {
      window.location.href = config.baseUrl;
    }

    dispatch(contractActions.setContractsList(securityContracts));
    dispatch(appActions.setUserProfile(userProfile));
  } catch (error) {
    console.error(error, "USER INFO REQUEST");
  }
};
