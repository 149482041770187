import { ExpiringCertificatesModel } from "types/proactiveSecurityApi.types";
import { pieChartLegendLabelFormatter } from "../ExposureDistributionChart/utils";
import {
  extraHighLevelDangerColor,
  highLevelDangerColor,
  lowLevelDangerColor,
  mediumLevelDangerColor,
} from "../ExposureScoreChart/utils";

export const composeChartOptions = (expiringCertificates: ExpiringCertificatesModel) => {
  return {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      borderRadius: 20,
      plotShadow: false,
      type: "pie",
      height: 350,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },
    tooltip: {
      pointFormat: "Quantity: <b>{point.y}</b><br> Distribution: <b>{point.percentage:.1f}%</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },
    series: [
      {
        data: [
          { name: "Expires in 90 days", y: expiringCertificates.expiresIn90, color: lowLevelDangerColor },
          { name: "Expires in 60 days", y: expiringCertificates.expiresIn60, color: mediumLevelDangerColor },
          { name: "Expires in 30 days", y: expiringCertificates.expiresIn30, color: highLevelDangerColor },
          { name: "Expired", y: expiringCertificates.expired, color: extraHighLevelDangerColor },
        ],
      },
    ],
    legend: {
      itemDistance: 20,
      alignColumns: true,
      useHTML: true,
      labelFormatter: pieChartLegendLabelFormatter,
    },
  };
};
