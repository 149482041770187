import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useThunkDispatch } from "store";
import { contractSelectors } from "store/contracts";
import { detectAndResponsePageSelectors, detectAndResponsePageThunks } from "store/detectAndResponsePage";

const useFetchDetectAndResponsePageData = () => {
  const thunkDispatch = useThunkDispatch();
  const contractsSD = useSelector(contractSelectors.getSDContractList);
  const selectedContractId = useSelector(contractSelectors.getSelectedContract)?.name as string;

  const isValidContractChosen = !!contractsSD.find((contract) => contract?.name === selectedContractId);

  useEffect(() => {
    if (selectedContractId && isValidContractChosen) {
      thunkDispatch(detectAndResponsePageThunks.getUnresolvedIncidentInfo(selectedContractId));
      thunkDispatch(detectAndResponsePageThunks.getIncidentSeverityInfo(selectedContractId));
      thunkDispatch(detectAndResponsePageThunks.getIncidentStatusInfo(selectedContractId));
      thunkDispatch(detectAndResponsePageThunks.getTop5IncidentsInfo(selectedContractId));
      thunkDispatch(detectAndResponsePageThunks.getAllIncidentsInfo(selectedContractId));
    }
  }, [thunkDispatch, selectedContractId, isValidContractChosen]);

  return useSelector(detectAndResponsePageSelectors.getSlice);
};

export default useFetchDetectAndResponsePageData;
