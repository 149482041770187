import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { useSelector } from "react-redux";
import CountUp from "react-countup";
import { proactiveSecurityPageSelectors, proactiveSecurityPageThunks } from "store/proactiveSecurityPage";
import { Card, Icon, Spinner } from "@skyportal/ui-kit";
import {
  composeExposureScoreChartOptions,
  highLevelDangerColor,
  lowLevelDangerColor,
  mediumLevelDangerColor,
} from "./utils";
import { RequestStatus } from "types/common.types";
import { useThunkDispatch } from "store";
import { contractSelectors } from "store/contracts";
import CardFallback from "containers/CardFallback";

import styles from "../../styles.module.css";

const ExposureScoreChart = () => {
  const { t } = useTranslation("proactiveSecurityPage");
  const { requestStatus, ...exposureScore } = useSelector(proactiveSecurityPageSelectors.getExposureScore);

  const thunkDispatch = useThunkDispatch();
  const selectedContractId = useSelector(contractSelectors.getSelectedContract)?.name as string;
  const reloadData = useCallback(
    () => thunkDispatch(proactiveSecurityPageThunks.getExposureScoreInfo(selectedContractId)),
    [selectedContractId, thunkDispatch]
  );

  const chartOptions = useMemo(() => composeExposureScoreChartOptions(exposureScore), [exposureScore]);

  return (
    <Card className={styles.card} title={t("exposureScoreChartTitle")} data-testid="exposureScoreChartContent">
      <div className={styles.cardContent}>
        {requestStatus === RequestStatus.PENDING && <Spinner className={styles.spinner} show data-testid="spinner" />}
        {requestStatus === RequestStatus.SUCCESS && (
          <>
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
            <div className={styles.customChartTitle} data-testid="exposureScoreChartSubtitle">
              <span>
                <CountUp end={exposureScore.currentScore} duration={0.6} preserveValue /> / 100
              </span>
            </div>
            <div className={styles.customLegend}>
              <div className={styles.customLegendItem}>
                <Icon type="fontawesome" icon="circle" color={lowLevelDangerColor} className={styles.chartLegendIcon} />
                {t("exposureScoreChartLegend.low")}
                <div className={styles.chartLegendValues}>{t("exposureScoreChartLegend.lowValues")}</div>
              </div>
              <div className={styles.customLegendItem}>
                <Icon
                  type="fontawesome"
                  icon="circle"
                  color={mediumLevelDangerColor}
                  className={styles.chartLegendIcon}
                />
                {t("exposureScoreChartLegend.medium")}
                <div className={styles.chartLegendValues}>{t("exposureScoreChartLegend.mediumValues")}</div>
              </div>
              <div className={styles.customLegendItem}>
                <Icon
                  type="fontawesome"
                  icon="circle"
                  color={highLevelDangerColor}
                  className={styles.chartLegendIcon}
                />
                {t("exposureScoreChartLegend.high")}
                <div className={styles.chartLegendValues}>{t("exposureScoreChartLegend.highValues")}</div>
              </div>
            </div>
          </>
        )}
        {requestStatus === RequestStatus.FAILURE && <CardFallback onReload={reloadData} />}
      </div>
    </Card>
  );
};

export default ExposureScoreChart;
