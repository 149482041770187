import React from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "layouts/PageLayout";
import PageHeader from "containers/PageHeader/PageHeader";
import useFetchStartPageData from "hooks/useFetchStartPageData";
import InfoBoxCards from "components/InfoBoxCards";
import StartPageCharts from "components/StartPageCharts";

const SecurityPage: React.FC = () => {
  const { t } = useTranslation("securityPage");
  useFetchStartPageData();

  return (
    <PageLayout>
      <PageHeader title={t("title")} withNavigationLinks />
      <InfoBoxCards />
      <StartPageCharts />
    </PageLayout>
  );
};

export default SecurityPage;
