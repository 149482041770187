import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useThunkDispatch } from "store";
import { contractSelectors } from "store/contracts";
import { startPageSelectors, startPageThunks } from "store/startPage";

const useFetchStartPageData = () => {
  const thunkDispatch = useThunkDispatch();
  const contractsSS = useSelector(contractSelectors.getSSContractList);
  const selectedContractId = useSelector(contractSelectors.getSelectedContract)?.name as string;

  const isValidContractChosen = !!contractsSS.find((contract) => contract?.name === selectedContractId);
  useEffect(() => {
    if (selectedContractId && isValidContractChosen) {
      thunkDispatch(startPageThunks.getSecureScoreInfo(selectedContractId));
      thunkDispatch(startPageThunks.getStaleDevicesInfo(selectedContractId));
      thunkDispatch(startPageThunks.getNoncompliantDevicesInfo(selectedContractId));
      thunkDispatch(startPageThunks.getUnmanagedDevicesInfo(selectedContractId));
      thunkDispatch(startPageThunks.getUsersInfo(selectedContractId));
      thunkDispatch(startPageThunks.getSecureScoreHistoryDevicesInfo(selectedContractId));
      thunkDispatch(startPageThunks.getSecureScoreCategorisedDevicesInfo(selectedContractId));
      thunkDispatch(startPageThunks.getIntuneEnrolledDevicesInfo(selectedContractId));
      thunkDispatch(startPageThunks.getRiskySigninsInfo(selectedContractId));
      thunkDispatch(startPageThunks.getRiskyUsersInfo(selectedContractId));
    }
  }, [thunkDispatch, selectedContractId, isValidContractChosen]);

  return useSelector(startPageSelectors.getSlice);
};

export default useFetchStartPageData;
