import { RequestStatus } from "types/common.types";
import * as types from "./startPage.types";

const initState: types.StartPageStateSlice = {
  secureScore: {
    currentPercentage: 0,
    currentPoints: 0,
    totalPoints: 0,
    targetPercentage: 0,
    requestStatus: RequestStatus.UNCALLED,
  },
  staleDevices: {
    count: 0,
    requestStatus: RequestStatus.UNCALLED,
  },
  noncompliantDevices: {
    count: 0,
    requestStatus: RequestStatus.UNCALLED,
  },
  unmanagedDevices: {
    count: 0,
    requestStatus: RequestStatus.UNCALLED,
  },
  users: {
    total: 0,
    mfaRegistered: 0,
    requestStatus: RequestStatus.UNCALLED,
  },
  secureScoreHistory: {
    list: [],
    requestStatus: RequestStatus.UNCALLED,
  },
  secureScoreCategorised: {
    list: [],
    requestStatus: RequestStatus.UNCALLED,
  },
  intuneEnrolledDevices: {
    list: [],
    requestStatus: RequestStatus.UNCALLED,
  },
  riskySignins: {
    list: [],
    requestStatus: RequestStatus.UNCALLED,
  },
  riskyUsers: {
    list: [],
    requestStatus: RequestStatus.UNCALLED,
  },
};

export default function contractReducer(
  state: types.StartPageStateSlice = initState,
  action: types.StartPageAction
): types.StartPageStateSlice {
  switch (action.type) {
    case types.SECURE_SCORE_REQUEST: {
      return {
        ...state,
        secureScore: { ...initState.secureScore, requestStatus: RequestStatus.PENDING },
      };
    }
    case types.SECURE_SCORE_REQUEST_SUCCESS: {
      return {
        ...state,
        secureScore: { ...action.payload, requestStatus: RequestStatus.SUCCESS },
      };
    }
    case types.SECURE_SCORE_REQUEST_FAILURE: {
      return {
        ...state,
        secureScore: { ...initState.secureScore, requestStatus: RequestStatus.FAILURE },
      };
    }
    case types.STALE_DEVICES_REQUEST: {
      return {
        ...state,
        staleDevices: { ...initState.staleDevices, requestStatus: RequestStatus.PENDING },
      };
    }
    case types.STALE_DEVICES_REQUEST_SUCCESS: {
      return {
        ...state,
        staleDevices: { ...action.payload, requestStatus: RequestStatus.SUCCESS },
      };
    }
    case types.STALE_DEVICES_REQUEST_FAILURE: {
      return {
        ...state,
        staleDevices: { ...initState.staleDevices, requestStatus: RequestStatus.FAILURE },
      };
    }
    case types.NONCOMPLIANT_DEVICES_REQUEST: {
      return {
        ...state,
        noncompliantDevices: { ...initState.noncompliantDevices, requestStatus: RequestStatus.PENDING },
      };
    }
    case types.NONCOMPLIANT_DEVICES_REQUEST_SUCCESS: {
      return {
        ...state,
        noncompliantDevices: { ...action.payload, requestStatus: RequestStatus.SUCCESS },
      };
    }
    case types.NONCOMPLIANT_DEVICES_REQUEST_FAILURE: {
      return {
        ...state,
        noncompliantDevices: { ...initState.noncompliantDevices, requestStatus: RequestStatus.FAILURE },
      };
    }
    case types.UNMANAGED_DEVICES_REQUEST: {
      return {
        ...state,
        unmanagedDevices: { ...initState.unmanagedDevices, requestStatus: RequestStatus.PENDING },
      };
    }
    case types.UNMANAGED_DEVICES_REQUEST_SUCCESS: {
      return {
        ...state,
        unmanagedDevices: { ...action.payload, requestStatus: RequestStatus.SUCCESS },
      };
    }
    case types.UNMANAGED_DEVICES_REQUEST_FAILURE: {
      return {
        ...state,
        unmanagedDevices: { ...initState.unmanagedDevices, requestStatus: RequestStatus.FAILURE },
      };
    }
    case types.USERS_REQUEST: {
      return {
        ...state,
        users: { ...initState.users, requestStatus: RequestStatus.PENDING },
      };
    }
    case types.USERS_REQUEST_SUCCESS: {
      return {
        ...state,
        users: { ...action.payload, requestStatus: RequestStatus.SUCCESS },
      };
    }
    case types.USERS_REQUEST_FAILURE: {
      return {
        ...state,
        users: { ...initState.users, requestStatus: RequestStatus.FAILURE },
      };
    }
    case types.SECURE_SCORE_HISTORY_REQUEST: {
      return {
        ...state,
        secureScoreHistory: { ...state.secureScoreHistory, requestStatus: RequestStatus.PENDING },
      };
    }
    case types.SECURE_SCORE_HISTORY_REQUEST_SUCCESS: {
      return {
        ...state,
        secureScoreHistory: { list: action.payload, requestStatus: RequestStatus.SUCCESS },
      };
    }
    case types.SECURE_SCORE_HISTORY_REQUEST_FAILURE: {
      return {
        ...state,
        secureScoreHistory: { ...initState.secureScoreHistory, requestStatus: RequestStatus.FAILURE },
      };
    }
    case types.SECURE_SCORE_CATEGORISED_REQUEST: {
      return {
        ...state,
        secureScoreCategorised: { ...state.secureScoreCategorised, requestStatus: RequestStatus.PENDING },
      };
    }
    case types.SECURE_SCORE_CATEGORISED_REQUEST_SUCCESS: {
      return {
        ...state,
        secureScoreCategorised: { list: action.payload, requestStatus: RequestStatus.SUCCESS },
      };
    }
    case types.SECURE_SCORE_CATEGORISED_REQUEST_FAILURE: {
      return {
        ...state,
        secureScoreCategorised: { ...initState.secureScoreCategorised, requestStatus: RequestStatus.FAILURE },
      };
    }
    case types.INTUNE_ENROLLED_DEVICES_REQUEST: {
      return {
        ...state,
        intuneEnrolledDevices: { ...state.intuneEnrolledDevices, requestStatus: RequestStatus.PENDING },
      };
    }
    case types.INTUNE_ENROLLED_DEVICES_REQUEST_SUCCESS: {
      return {
        ...state,
        intuneEnrolledDevices: { list: action.payload, requestStatus: RequestStatus.SUCCESS },
      };
    }
    case types.INTUNE_ENROLLED_DEVICES_REQUEST_FAILURE: {
      return {
        ...state,
        intuneEnrolledDevices: { ...initState.intuneEnrolledDevices, requestStatus: RequestStatus.FAILURE },
      };
    }
    case types.RISKY_SIGNINS_REQUEST: {
      return {
        ...state,
        riskySignins: { ...state.riskySignins, requestStatus: RequestStatus.PENDING },
      };
    }
    case types.RISKY_SIGNINS_REQUEST_SUCCESS: {
      return {
        ...state,
        riskySignins: { list: action.payload, requestStatus: RequestStatus.SUCCESS },
      };
    }
    case types.RISKY_SIGNINS_REQUEST_FAILURE: {
      return {
        ...state,
        riskySignins: { ...initState.riskySignins, requestStatus: RequestStatus.FAILURE },
      };
    }
    case types.RISKY_USERS_REQUEST: {
      return {
        ...state,
        riskyUsers: { ...state.riskyUsers, requestStatus: RequestStatus.PENDING },
      };
    }
    case types.RISKY_USERS_REQUEST_SUCCESS: {
      return {
        ...state,
        riskyUsers: { list: action.payload, requestStatus: RequestStatus.SUCCESS },
      };
    }
    case types.RISKY_USERS_REQUEST_FAILURE: {
      return {
        ...state,
        riskyUsers: { ...initState.riskyUsers, requestStatus: RequestStatus.FAILURE },
      };
    }
    default:
      return state;
  }
}
