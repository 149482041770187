export interface BreadcrumbsItem {
  label: string;
  path: string;
  dustinIcon?: string;
}

export interface BreadcrumbsConfigItem {
  label: string;
  crumbs?: BreadcrumbsItem[];
  dustinIcon?: string;
  match: { path: string; exact?: boolean };
}

const Security: BreadcrumbsItem = {
  label: "Security",
  path: "/security/security-ready",
  dustinIcon: "zoom-in",
};

const breadcrumbsConfig: BreadcrumbsConfigItem[] = [
  {
    label: Security.label,
    dustinIcon: Security.dustinIcon,
    match: {
      path: Security.path,
      exact: true,
    },
  },
  {
    label: "Proactive Security",
    crumbs: [Security],
    dustinIcon: "security",
    match: {
      path: "/security/proactive-security",
    },
  },
  {
    label: "Detection and Response",
    crumbs: [Security],
    dustinIcon: "security-shield",
    match: {
      path: "/security/detection-and-response",
    },
  },
];

export default breadcrumbsConfig;
