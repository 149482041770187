import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import { useDispatch } from "react-redux";
import PageLayout from "layouts/PageLayout";
import PageHeader from "containers/PageHeader/PageHeader";
import Tabs, { TabsConfigModel } from "containers/Tabs/Tabs";
import { detectAndResponsePageActions } from "store/detectAndResponsePage";
import { emptyAllIncidentsFilters } from "store/detectAndResponsePage/detectAndResponsePage.reducer";
import useFetchDetectAndResponsePageData from "hooks/useFetchDetectAndResponsePageData";
import DetectAndResponseOverview from "components/DetectAndResponseOverview";
import DetectAndResponseIncidents from "components/DetectAndResponseIncidents";
import DetectAndResponseDetailedIncident from "components/DetectAndResponseDetailedIncident/DetectAndResponseDetailedIncident";

const tabsConfig: TabsConfigModel[] = [
  { name: "overviewTab", path: "/security/detection-and-response/overview" },
  { name: "incidentsTab", path: "/security/detection-and-response/incidents" },
];

const DetectAndResponsePage: React.FC = () => {
  const { t } = useTranslation("detectAndResponsePage");
  const dispatch = useDispatch();
  useFetchDetectAndResponsePageData();

  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const [activeTab, setActiveTab] = useState<string>(
    tabsConfig.find((tab) => pathname.startsWith(tab.path))?.name || tabsConfig[0].name
  );

  useEffect(() => {
    setActiveTab(tabsConfig.find((tab) => pathname.startsWith(tab.path))?.name || tabsConfig[0].name);
  }, [dispatch, pathname]);

  return (
    <PageLayout>
      <PageHeader title={t("title")} />
      <Tabs
        tabsConfig={tabsConfig}
        localeObjectName="detectAndResponsePage"
        activeTab={activeTab}
        resetCurrentPage={detectAndResponsePageActions.setAllIncidentsCurrentPageAC}
        resetFilters={detectAndResponsePageActions.setAllIncidentsFiltersAC}
        emptyFiltersObject={emptyAllIncidentsFilters}
      />
      <Switch>
        <Route path={`${path}/overview`} exact component={DetectAndResponseOverview} />
        <Route path={`${path}/incidents`} exact component={DetectAndResponseIncidents} />
        <Route path={`${path}/incidents/:incidentId`} exact component={DetectAndResponseDetailedIncident} />
        <Route path="*">
          <Redirect to={`${path}/overview`} />
        </Route>
      </Switch>
    </PageLayout>
  );
};

export default DetectAndResponsePage;
