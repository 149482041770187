import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { useSelector } from "react-redux";
import cn from "classnames";
import { startPageSelectors, startPageThunks } from "store/startPage";
import { composeChartOptions } from "./utils";
import { Card, Spinner } from "@skyportal/ui-kit";
import { RequestStatus } from "types/common.types";
import CardFallback from "containers/CardFallback";
import { useThunkDispatch } from "store";
import { contractSelectors } from "store/contracts";

import styles from "../../styles.module.css";

const SecureScoreHistoryChart = () => {
  const { t } = useTranslation("securityPage");
  const { requestStatus, ...chartValues } = useSelector(startPageSelectors.getSecureScoreHistory);
  const { targetPercentage } = useSelector(startPageSelectors.getSecureScore);

  const chartOptions = useMemo(
    () => composeChartOptions(chartValues.list, targetPercentage),
    [chartValues, targetPercentage]
  );

  const thunkDispatch = useThunkDispatch();
  const selectedContractId = useSelector(contractSelectors.getSelectedContract)?.name as string;
  const reloadData = useCallback(
    () => thunkDispatch(startPageThunks.getSecureScoreHistoryDevicesInfo(selectedContractId)),
    [selectedContractId, thunkDispatch]
  );

  useEffect(() => {
    // adjusts Highchart width to parent container width
    window.dispatchEvent(new Event("resize"));
  }, []);

  return (
    <Card
      className={cn(styles.card, styles.contentWithMargins)}
      title={t("secureScoreHistoryChartTitle")}
      data-testid="secureScoreHistoryChartContent"
    >
      <div className={styles.cardContent}>
        {requestStatus === RequestStatus.PENDING && <Spinner show />}
        {requestStatus === RequestStatus.SUCCESS && <HighchartsReact highcharts={Highcharts} options={chartOptions} />}
        {requestStatus === RequestStatus.FAILURE && <CardFallback onReload={reloadData} />}
      </div>
    </Card>
  );
};

export default SecureScoreHistoryChart;
