import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Spinner } from "@skyportal/ui-kit";
import { Link } from "react-router-dom";
import {
  detectAndResponsePageThunks,
  detectAndResponsePageSelectors,
  detectAndResponsePageActions,
} from "store/detectAndResponsePage";
import { RequestStatus } from "types/common.types";
import { useThunkDispatch } from "store";
import { contractSelectors } from "store/contracts";
import CardFallback from "containers/CardFallback";

import styles from "../../styles.module.css";

const UnresolvedIncidentsChart = () => {
  const { t } = useTranslation("detectAndResponsePage");
  const dispatch = useDispatch();
  const { requestStatus, count } = useSelector(detectAndResponsePageSelectors.getUnresolvedIncidents);

  const handleViewUnresolvedBtnClick = () => {
    dispatch(
      detectAndResponsePageActions.setAllIncidentsFiltersAC({ status: "Unresolved", severity: "", id: "", subject: "" })
    );
  };

  const thunkDispatch = useThunkDispatch();
  const selectedContractId = useSelector(contractSelectors.getSelectedContract)?.name as string;
  const reloadData = useCallback(
    () => thunkDispatch(detectAndResponsePageThunks.getUnresolvedIncidentInfo(selectedContractId)),
    [selectedContractId, thunkDispatch]
  );

  return (
    <Card
      className={styles.card}
      title={t("unresolvedIncidentsChartTitle")}
      data-testid="unresolvedIncidentsChartContent"
    >
      <div className={styles.cardContent}>
        {requestStatus === RequestStatus.PENDING && <Spinner show />}
        {requestStatus === RequestStatus.SUCCESS && (
          <div className={styles.unresolvedIncident}>
            <span className={styles.unresolvedIncidentCount}>{count || 0}</span>
            <div>
              <Link to={"/security/detection-and-response/incidents"}>
                <Button rightIcon="arrow-right" onClick={handleViewUnresolvedBtnClick}>
                  {t("viewAllUnresolvedBtn")}
                </Button>
              </Link>
            </div>
          </div>
        )}
        {requestStatus === RequestStatus.FAILURE && <CardFallback onReload={reloadData} />}
      </div>
    </Card>
  );
};

export default UnresolvedIncidentsChart;
