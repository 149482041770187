import { UserPermissionObjectInfo } from "@skyportal/auth-web-client";

export interface ContractModel extends UserPermissionObjectInfo {}

export type selectedContractId = string | null;

export interface ContractsStateSlice {
  readonly SS: ContractModel[];
  readonly SE: ContractModel[];
  readonly SD: ContractModel[];
  readonly selectedContract: ContractModel | null;
}

export const SET_CONTRACTS_LIST = "SET_CONTRACTS_LIST";
export type SetContractsListAction = { type: typeof SET_CONTRACTS_LIST; payload: ContractModel[] };
export type SetContractsListAC = (payload: ContractModel[]) => SetContractsListAction;

export const SELECT_CONTRACT = "SELECT_CONTRACT";
export type SelectContractAction = { type: typeof SELECT_CONTRACT; payload: ContractModel };
export type SelectContractAC = (payload: ContractModel) => SelectContractAction;

export type ContractActions = SetContractsListAction | SelectContractAction;
