import Highcharts from "highcharts";
import { SecureScoreHistoryModel } from "types/startPageApi.types";

export const composeChartOptions = (secureScoreHistoryData: SecureScoreHistoryModel[], targetPercentage: number) => {
  return {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      borderRadius: 20,
      spacingTop: 20,
      plotShadow: false,
      type: "areaspline",
      height: 350,
    },
    colors: ["#c48df7", "#4ef09d", "#44d9fc", "#e59a7f", "#ff5a5a", "#c4c4c4"],
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },
    tooltip: {
      split: true,
      // Target Percentage in tooltip
      // pointFormat: `<span style="color:#c48df7">{series.name}:</span> <b>{point.y}%</b><br><span style="color:#808080">Target:</span> <b>${targetPercentage}%</b>`,
      pointFormat: `<span style="color:#c48df7">{series.name}:</span> <b>{point.y}%</b><br><span style="color:#808080">`,
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    yAxis: {
      title: null,
      max: 100,
      labels: {
        format: "{value}%",
      },
      gridLineWidth: 0,
      // Target Percentage line
      // plotLines: [
      //   {
      //     value: targetPercentage,
      //     color: "rgb(0 0 0 / 50%)",
      //     width: 2,
      //     dashStyle: "dot",
      //     zIndex: 5,
      //   },
      // ],
    },
    xAxis: {
      title: null,
      type: "datetime",
      gridLineWidth: 1,
    },
    plotOptions: {
      areaspline: {
        animation: {
          duration: 1500,
        },
        marker: {
          enabled: false,
        },
      },
    },
    series: [
      {
        name: "Score",
        data: secureScoreHistoryData.map((item) => {
          const timestamp = Number(new Date(item.date));
          return { x: timestamp, y: item.score };
        }),
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [0, new Highcharts.Color("#c48df7").setOpacity(1).get("rgba")],
            [1, new Highcharts.Color("#c48df7").setOpacity(0.2).get("rgba")],
          ],
        },
      },
    ],
    legend: {
      enabled: false,
    },
  };
};
