import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, Table, Tooltip } from "@skyportal/ui-kit";
import { useTranslation } from "react-i18next";
import { detectAndResponsePageActions, detectAndResponsePageSelectors } from "store/detectAndResponsePage";
import { LogItem } from "types/detectAndResponseApi.types";
import { formatDateAndTime } from "utils/time";
import PaginationBox from "containers/PaginationBox/PaginationBox";

import styles from "./styles.module.css";

const MAX_INFORMATION_COLUMN_LENGTH = 200;
const MAX_INFORMATION_TOOLTIP_LENGTH = 500;

const DetailedIncidentLog = () => {
  const { t } = useTranslation("detectAndResponsePage");
  const dispatch = useDispatch();
  const { logPerPage, logCurrentPage } = useSelector(detectAndResponsePageSelectors.getDetailedIncident);
  const { list, pageCount } = useSelector(detectAndResponsePageSelectors.getDetailedIncidentLog);
  const [isLogModalOpen, setIsLogModalOpen] = useState(false);
  const [currentLogItem, setCurrentLogItem] = useState<LogItem>({ logDate: "", information: "" });

  const handleOpenModalBtn = useCallback((logItem: LogItem) => {
    setCurrentLogItem(logItem);
    setIsLogModalOpen(true);
  }, []);
  const handleCloseModalBtn = useCallback(() => setIsLogModalOpen(false), []);

  const columnsConfig = useMemo(
    () => [
      {
        key: "logDate",
        dataIndex: "logDate",
        title: t("detailedIncidentLogColumns.logDate"),
        sortDisabled: true,
        width: "20%",
        render: (logDate: LogItem["logDate"], logItem: LogItem) => (
          <div className={styles.incidentLogClickableCell} onClick={() => handleOpenModalBtn(logItem)}>
            {formatDateAndTime(logDate)}
          </div>
        ),
      },
      {
        key: "information",
        dataIndex: "information",
        title: t("detailedIncidentLogColumns.information"),
        sortDisabled: true,
        width: "60%",
        render: (information: LogItem["information"], logItem: LogItem) => (
          <div className={styles.incidentLogClickableCell} onClick={() => handleOpenModalBtn(logItem)}>
            {information.length > MAX_INFORMATION_COLUMN_LENGTH ? (
              <Tooltip title={`${information.substring(0, MAX_INFORMATION_TOOLTIP_LENGTH)}...`}>
                <span>{`${information.substring(0, MAX_INFORMATION_COLUMN_LENGTH)}...`}</span>
              </Tooltip>
            ) : (
              information
            )}
          </div>
        ),
      },
    ],
    [handleOpenModalBtn, t]
  );

  const handlePerPageChange = useCallback(
    (value) => dispatch(detectAndResponsePageActions.setDetailedIncidentLogPerPageAC(value)),
    [dispatch]
  );
  const handlePageChange = useCallback(
    (value) => dispatch(detectAndResponsePageActions.setDetailedIncidentLogCurrentPageAC(value)),
    [dispatch]
  );

  return (
    <>
      <div className={styles.detailedIncidentLogTable} data-testid="detailedIncidentLog">
        <div className={styles.detailedIncidentLogTableBlock}>
          <Table dataSource={list} rowKey="detailedIncidentLog" columns={columnsConfig} />
        </div>
        <PaginationBox
          perPage={logPerPage}
          currentPage={logCurrentPage}
          pageCount={pageCount}
          onPerPageChange={handlePerPageChange}
          onChangePage={handlePageChange}
        />
      </div>
      <Modal
        open={isLogModalOpen}
        onCloseBtnClick={handleCloseModalBtn}
        controls={
          <Button onClick={handleCloseModalBtn} data-testid="detailedIncidentLogCloseBtn">
            {t("common:Close")}
          </Button>
        }
        title={t("detailedIncidentLog")}
        data-testid="detailedIncidentLogModal"
      >
        <div className={styles.incidentLogSubtitle}>{t("detailedIncidentLogColumns.logDate")}</div>
        <div className={styles.incidentLogInfo}>{formatDateAndTime(currentLogItem.logDate) || "-"}</div>
        <div className={styles.incidentLogSubtitle}>{t("detailedIncidentLogColumns.information")}</div>
        <div className={styles.incidentLogInfo}>{currentLogItem.information || "-"}</div>
      </Modal>
    </>
  );
};

export default DetailedIncidentLog;
