import React, { useCallback, useMemo } from "react";
import { Button, Card, Select, Spinner, Table, TextInput } from "@skyportal/ui-kit";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useThunkDispatch } from "store";
import {
  detectAndResponsePageActions,
  detectAndResponsePageSelectors,
  detectAndResponsePageThunks,
} from "store/detectAndResponsePage";
import { RequestStatus } from "types/common.types";
import { IncidentModel } from "types/detectAndResponseApi.types";
import { contractSelectors } from "store/contracts";
import PaginationBox from "containers/PaginationBox/PaginationBox";
import { incidentStatusColorChartMap } from "components/DetectAndResponseOverview/charts/IncidentStatusChart/utils";
import { incidentSeverityColorChartMap } from "components/DetectAndResponseOverview/charts/IncidentSeverityChart/utils";
import CardFallback from "containers/CardFallback/CardFallback";
import { areFiltersEmpty } from "utils/helpers";
import { formatDateAndTime } from "utils/time";

import styles from "./styles.module.css";

export type SelectOptionItem = {
  value: string;
  label: string;
};

export const dummyIncident: IncidentModel = {
  status: "_",
  severity: "_",
  id: "_",
  subject: "_",
  created: "",
  updated: "",
};

export const incidentStatusOptions: SelectOptionItem[] = [
  { label: "", value: "" },
  { label: "Resolved", value: "Resolved" },
  { label: "Accepted risk", value: "Accepted risk" },
  { label: "Dispensation", value: "Dispensation" },
  { label: "Mitigated", value: "Mitigated" },
  { label: "Unresolved", value: "Unresolved" },
];

export const incidentSeverityOptions: SelectOptionItem[] = [
  { label: "", value: "" },
  { label: "Informational", value: "Informational" },
  { label: "Low", value: "Low" },
  { label: "Medium", value: "Medium" },
  { label: "High", value: "High" },
  { label: "Critical", value: "Critical" },
];

const DetectAndResponseIncidents = () => {
  const { t } = useTranslation("detectAndResponsePage");
  const dispatch = useDispatch();
  const selectedContractId = useSelector(contractSelectors.getSelectedContract)?.name as string;
  const { filters, sortField, sortOrder, perPage, currentPage, requestStatus } = useSelector(
    detectAndResponsePageSelectors.getAllIncidents
  );
  const { list, pageCount } = useSelector(detectAndResponsePageSelectors.getAllIncidentsTable);

  const handleSelectFilterChange = useCallback(
    (value, fieldName) => {
      dispatch(detectAndResponsePageActions.setAllIncidentsFiltersAC({ ...filters, [fieldName]: value }));
      dispatch(detectAndResponsePageActions.setAllIncidentsCurrentPageAC(1));
    },
    [dispatch, filters]
  );
  const handleFilterChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, fieldName) => {
      dispatch(detectAndResponsePageActions.setAllIncidentsFiltersAC({ ...filters, [fieldName]: event.target.value }));
      dispatch(detectAndResponsePageActions.setAllIncidentsCurrentPageAC(1));
    },
    [dispatch, filters]
  );

  const columnsConfig = useMemo(
    () => [
      {
        key: "status",
        dataIndex: "status",
        title: t("allIncidentsTableColumns.status"),
        width: "10%",
        render: (status: IncidentModel["status"]) =>
          status !== "_" ? (
            <div className={styles.incidentTag} style={{ backgroundColor: incidentStatusColorChartMap[status] }}>
              {status}
            </div>
          ) : (
            <Select
              data-testid="tableFilter-status"
              className={styles.allIncidentsSelect}
              options={incidentStatusOptions}
              value={filters.status}
              onChange={(value) => handleSelectFilterChange(value, "status")}
            />
          ),
      },
      {
        key: "severity",
        dataIndex: "severity",
        title: t("allIncidentsTableColumns.severity"),
        width: "10%",
        render: (severity: IncidentModel["severity"]) =>
          severity !== "_" ? (
            <div className={styles.incidentTag} style={{ backgroundColor: incidentSeverityColorChartMap[severity] }}>
              {severity}
            </div>
          ) : (
            <Select
              data-testid="tableFilter-severity"
              className={styles.allIncidentsSelect}
              options={incidentSeverityOptions}
              value={filters.severity}
              onChange={(value) => handleSelectFilterChange(value, "severity")}
            />
          ),
      },
      {
        key: "id",
        dataIndex: "id",
        title: t("allIncidentsTableColumns.id"),
        width: "15%",
        render: (id: IncidentModel["id"]) =>
          id !== "_" ? (
            id
          ) : (
            <TextInput
              data-testid="tableFilter-id"
              className={styles.allIncidentsInput}
              value={filters.id}
              onChange={(e) => handleFilterChange(e, "id")}
            />
          ),
      },
      {
        key: "subject",
        dataIndex: "subject",
        title: t("allIncidentsTableColumns.subject"),
        width: "25%",
        render: (subject: IncidentModel["subject"]) =>
          subject !== "_" ? (
            subject
          ) : (
            <TextInput
              data-testid="tableFilter-subject"
              className={styles.allIncidentsInput}
              value={filters.subject}
              onChange={(e) => handleFilterChange(e, "subject")}
            />
          ),
      },
      {
        key: "created",
        dataIndex: "created",
        width: "15%",
        title: t("allIncidentsTableColumns.created"),
        render: (created: IncidentModel["created"]) => (created ? formatDateAndTime(created) : null),
      },
      {
        key: "updated",
        dataIndex: "updated",
        width: "15%",
        title: t("allIncidentsTableColumns.updated"),
        render: (updated: IncidentModel["updated"]) => (updated ? formatDateAndTime(updated) : null),
      },
      {
        key: "_",
        dataIndex: "_",
        title: "",
        sortDisabled: true,
        width: "10%",
        render: (_: undefined, incident: IncidentModel) =>
          incident.id !== "_" ? (
            <Link to={`/security/detection-and-response/incidents/${incident.id}`} className={styles.incidentBtn}>
              <Button size="small">{t("latestIncidentsTableColumns.viewDetailsBtn")}</Button>
            </Link>
          ) : null,
      },
    ],
    [filters, handleFilterChange, handleSelectFilterChange, t]
  );

  const handleSortFieldChange = useCallback(
    (field) => dispatch(detectAndResponsePageActions.setAllIncidentsSortFieldAC(field)),
    [dispatch]
  );
  const handlePerPageChange = useCallback(
    (value) => dispatch(detectAndResponsePageActions.setAllIncidentsPerPageAC(value)),
    [dispatch]
  );
  const handlePageChange = useCallback(
    (value) => dispatch(detectAndResponsePageActions.setAllIncidentsCurrentPageAC(value)),
    [dispatch]
  );

  const thunkDispatch = useThunkDispatch();
  const reloadData = useCallback(() => {
    thunkDispatch(detectAndResponsePageThunks.getAllIncidentsInfo(selectedContractId));
  }, [selectedContractId, thunkDispatch]);

  return (
    <div style={{ margin: "16px 0" }}>
      <Card className={styles.allIncidentsCard} data-testid="allIncidentsContent">
        <div className={styles.allIncidentsCardContent}>
          <div className={styles.allIncidentsTable}>
            {(requestStatus === RequestStatus.PENDING || requestStatus === RequestStatus.UNCALLED) && <Spinner show />}
            {requestStatus === RequestStatus.SUCCESS && (
              <>
                <div className={styles.allIncidentsTableBlock}>
                  <Table
                    dataSource={!list.length && areFiltersEmpty(filters) ? [] : [dummyIncident, ...list]}
                    rowKey="incident"
                    columns={columnsConfig}
                    sortField={sortField}
                    sortOrder={sortOrder}
                    onSortFieldChange={handleSortFieldChange}
                  />
                </div>
                <PaginationBox
                  perPage={perPage}
                  currentPage={currentPage}
                  pageCount={pageCount}
                  onPerPageChange={handlePerPageChange}
                  onChangePage={handlePageChange}
                />
              </>
            )}
            {requestStatus === RequestStatus.FAILURE && <CardFallback onReload={reloadData} />}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default DetectAndResponseIncidents;
