import React, { useCallback, useState } from "react";
import { Button, Card, Icon } from "@skyportal/ui-kit";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import useFetchDetailedIncidentPageData from "hooks/useFetchDetailedIncidentPageData";
import { useThunkDispatch } from "store";
import { detectAndResponsePageSelectors, detectAndResponsePageThunks } from "store/detectAndResponsePage";
import { RequestStatus } from "types/common.types";
import { contractSelectors } from "store/contracts";
import { incidentStatusColorChartMap } from "components/DetectAndResponseOverview/charts/IncidentStatusChart/utils";
import { incidentSeverityColorChartMap } from "components/DetectAndResponseOverview/charts/IncidentSeverityChart/utils";
import { AffectedConfigurationItem } from "types/detectAndResponseApi.types";
import { generatePdfExportUrl } from "utils/helpers";
import SendModal from "containers/ExportModals/SendModal";
import DetailedIncidentLog from "./DetailedIncidentLog";
import CardFallback from "containers/CardFallback/CardFallback";
import { formatDateAndTime } from "utils/time";

import styles from "./styles.module.css";

const iconMap: Record<string, string> = {
  HOST: "laptop",
  USER: "user",
};

const DetectAndResponseDetailedIncident = () => {
  const { t } = useTranslation("detectAndResponsePage");
  const { pathname } = useLocation();
  const incidentId = pathname.replace("/security/detection-and-response/incidents/", "");
  useFetchDetailedIncidentPageData(incidentId);

  const selectedContractId = useSelector(contractSelectors.getSelectedContract)?.name as string;
  const { requestStatus, ...incidentData } = useSelector(detectAndResponsePageSelectors.getDetailedIncident);

  const [isSendModalOpen, setIsSendModalOpen] = useState(false);
  const handleSendBtnClick = useCallback(() => setIsSendModalOpen(true), []);
  const handleSendModalClose = useCallback(() => setIsSendModalOpen(false), []);

  const thunkDispatch = useThunkDispatch();
  const reloadData = useCallback(() => {
    thunkDispatch(detectAndResponsePageThunks.getDetailedIncidentInfo(selectedContractId, incidentId));
  }, [incidentId, selectedContractId, thunkDispatch]);

  return (
    <>
      <div className={styles.detailedIncidentNavigation} data-testid="detailedIncidentNavigation">
        <Link to={"/security/detection-and-response/incidents"}>
          <Button leftIcon="arrow-left">{t("backToIncidentListBtn")}</Button>
        </Link>
        <div>
          <a href={generatePdfExportUrl(selectedContractId, `incidents/${incidentId}`)} download>
            <Button disabled={!incidentId}>{t("common:Export")}</Button>
          </a>
          <Button onClick={handleSendBtnClick} disabled={!incidentId} style={{ marginLeft: "16px" }}>
            {t("common:Send to")}
          </Button>
        </div>
      </div>
      <Card
        title={incidentData.subject}
        className={styles.detailedIncidentCard}
        loading={requestStatus === RequestStatus.UNCALLED || requestStatus === RequestStatus.PENDING}
        data-testid="detailedIncidentContent"
      >
        <div className={styles.detailedIncidentCardContent}>
          {requestStatus === RequestStatus.SUCCESS && (
            <div className={styles.detailedIncidentRoot}>
              <div className={styles.detailedIncidentInfo}>
                <div className={styles.detailedIncidentInfoCol}>
                  <div>
                    <span>{t("detailedIncidentStatus")}</span>
                    <span
                      className={styles.incidentTag}
                      style={{ backgroundColor: incidentStatusColorChartMap[incidentData.status] }}
                    >
                      {incidentData.status}
                    </span>
                  </div>
                  <div>
                    <span>{t("detailedIncidentSeverity")}</span>
                    <span
                      className={styles.incidentTag}
                      style={{ backgroundColor: incidentSeverityColorChartMap[incidentData.severity] }}
                    >
                      {incidentData.severity}
                    </span>
                  </div>
                </div>
                <div className={styles.detailedIncidentInfoCol}>
                  <div>
                    {t("detailedIncidentId")}
                    {incidentData.id || "-"}
                  </div>
                  <div>
                    {t("detailedIncidentCreated")}
                    {incidentData.created ? formatDateAndTime(incidentData.created) : "–"}
                  </div>
                </div>
                <div className={styles.detailedIncidentInfoCol}>
                  <div>
                    {t("detailedIncidentClosed")}
                    {incidentData.closed ? formatDateAndTime(incidentData.closed) : "–"}
                  </div>
                  <div>
                    {t("detailedIncidentLastUpdated")}
                    {incidentData.updated ? formatDateAndTime(incidentData.updated) : "–"}
                  </div>
                </div>
                <div className={styles.detailedIncidentInfoCol}>
                  <div>
                    {t("detailedIncidentRevision")}
                    {incidentData.revision || "–"}
                  </div>
                  <div>
                    {t("detailedIncidentReopened")}
                    {incidentData.reopen ? formatDateAndTime(incidentData.reopen) : "–"}
                  </div>
                </div>
              </div>
              {incidentData.affectedConfigurationItems.length ? (
                <>
                  <div className={styles.detailedIncidentSubtitle}>
                    {t("detailedIncidentAffectedConfigurationItems")}
                  </div>
                  <div className={styles.detailedIncidentInfo}>
                    {incidentData.affectedConfigurationItems.map((item: AffectedConfigurationItem) => (
                      <div className={styles.affectedConfigurationItem}>
                        <Icon icon={iconMap[item.type]} height={24} />
                        {item.id}
                      </div>
                    ))}
                  </div>
                </>
              ) : null}
              <div className={styles.detailedIncidentInfo}>
                <div className={styles.detailedIncidentSummaryCol}>
                  <div className={styles.detailedIncidentSubtitle}>{t("detailedIncidentSummary")}</div>
                  <div className={styles.detailedIncidentInfo}>{incidentData.summary || "–"}</div>
                  <div className={styles.detailedIncidentSubtitle}>{t("detailedIncidentTechnicalDetails")}</div>
                  <div className={styles.detailedIncidentInfo}>{incidentData.technicalDetails || "–"}</div>
                </div>
                <div className={styles.detailedIncidentSummaryCol}>
                  <div className={styles.detailedIncidentSubtitle}>{t("detailedIncidentRecommendedAction")}</div>
                  <div className={styles.detailedIncidentInfo}>{incidentData.recommendedAction || "–"}</div>
                </div>
              </div>
              <div className={styles.detailedIncidentSubtitle} style={{ fontSize: "18px" }}>
                {t("detailedIncidentLog")}
              </div>
              <div className={styles.detailedIncidentInfo}>
                <DetailedIncidentLog />
              </div>
            </div>
          )}
          {requestStatus === RequestStatus.FAILURE && <CardFallback onReload={reloadData} />}
        </div>
      </Card>
      <SendModal open={isSendModalOpen} onClose={handleSendModalClose} incidentId={incidentId} />
    </>
  );
};

export default DetectAndResponseDetailedIncident;
