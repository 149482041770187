import { RootState } from "../index";

export const getSlice = (state: RootState) => state.proactiveSecurityPage;

export const getExposureScore = (state: RootState) => getSlice(state).exposureScore;
export const getExposureDistribution = (state: RootState) => getSlice(state).exposureDistribution;
export const getExpiringCertificates = (state: RootState) => getSlice(state).expiringCertificates;
export const getDeviceScoreHistory = (state: RootState) => getSlice(state).deviceScoreHistory;
export const getVulnerableSoftware = (state: RootState) => getSlice(state).vulnerableSoftware;
export const getRecommendations = (state: RootState) => getSlice(state).recommendations;
