import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useThunkDispatch } from "store";
import { contractSelectors } from "store/contracts";
import { detectAndResponsePageSelectors, detectAndResponsePageThunks } from "store/detectAndResponsePage";

const useFetchDetailedIncidentPageData = (incidentId: string) => {
  const thunkDispatch = useThunkDispatch();
  const selectedContractId = useSelector(contractSelectors.getSelectedContract)?.name as string;
  const { id: previouslyFetchedIncidentId } = useSelector(detectAndResponsePageSelectors.getDetailedIncident);

  useEffect(() => {
    if (selectedContractId && incidentId !== previouslyFetchedIncidentId) {
      thunkDispatch(detectAndResponsePageThunks.getDetailedIncidentInfo(selectedContractId, incidentId));
    }
  }, [thunkDispatch, selectedContractId, incidentId]);

  return useSelector(detectAndResponsePageSelectors.getDetailedIncident);
};

export default useFetchDetailedIncidentPageData;
