import config from "config";
import { selectedContractId } from "store/contracts/contracts.types";

export const parseApiErrorMessage = (error: any): string | null => {
  if (!error || !error.isAxiosError) {
    return null;
  }

  try {
    const { msg: message } = error.response?.data;
    return message || null;
  } catch (e) {
    return null;
  }
};

export const generatePdfExportUrl = (selectedContractId: selectedContractId, path: string) => {
  return [config.securityApiUrl, `/v1/contracts/${selectedContractId}/security/${path}?accept=pdf`].join("");
};

export const areFiltersEmpty = (filters: Object) => Object.values(filters).every((value) => value === "");
