import * as types from "./detectAndResponsePage.types";

export const unresolvedIncidentsRequestAC: types.UnresolvedIncidentsRequestAC = () => ({
  type: types.UNRESOLVED_INCIDENTS_REQUEST,
});

export const unresolvedIncidentsRequestSuccessAC: types.UnresolvedIncidentsRequestSuccessAC = (payload) => ({
  type: types.UNRESOLVED_INCIDENTS_REQUEST_SUCCESS,
  payload,
});

export const unresolvedIncidentsRequestFailureAC: types.UnresolvedIncidentsRequestFailureAC = () => ({
  type: types.UNRESOLVED_INCIDENTS_REQUEST_FAILURE,
});

export const incidentSeverityRequestAC: types.IncidentSeverityRequestAC = () => ({
  type: types.INCIDENT_SEVERITY_REQUEST,
});

export const incidentSeverityRequestSuccessAC: types.IncidentSeverityRequestSuccessAC = (payload) => ({
  type: types.INCIDENT_SEVERITY_REQUEST_SUCCESS,
  payload,
});

export const incidentSeverityRequestFailureAC: types.IncidentSeverityRequestFailureAC = () => ({
  type: types.INCIDENT_SEVERITY_REQUEST_FAILURE,
});

export const incidentStatusRequestAC: types.IncidentStatusRequestAC = () => ({
  type: types.INCIDENT_STATUS_REQUEST,
});

export const incidentStatusRequestSuccessAC: types.IncidentStatusRequestSuccessAC = (payload) => ({
  type: types.INCIDENT_STATUS_REQUEST_SUCCESS,
  payload,
});

export const incidentStatusRequestFailureAC: types.IncidentStatusRequestFailureAC = () => ({
  type: types.INCIDENT_STATUS_REQUEST_FAILURE,
});

export const top5IncidentsRequestAC: types.Top5IncidentsRequestAC = () => ({
  type: types.TOP_5_INCIDENTS_REQUEST,
});

export const top5IncidentsRequestSuccessAC: types.Top5IncidentsRequestSuccessAC = (payload) => ({
  type: types.TOP_5_INCIDENTS_REQUEST_SUCCESS,
  payload,
});

export const top5IncidentsRequestFailureAC: types.Top5IncidentsRequestFailureAC = () => ({
  type: types.TOP_5_INCIDENTS_REQUEST_FAILURE,
});

export const allIncidentsRequestAC: types.AllIncidentsRequestAC = () => ({
  type: types.ALL_INCIDENTS_REQUEST,
});

export const allIncidentsRequestSuccessAC: types.AllIncidentsRequestSuccessAC = (payload) => ({
  type: types.ALL_INCIDENTS_REQUEST_SUCCESS,
  payload,
});

export const allIncidentsRequestFailureAC: types.AllIncidentsRequestFailureAC = () => ({
  type: types.ALL_INCIDENTS_REQUEST_FAILURE,
});

export const setAllIncidentsFiltersAC: types.SetAllIncidentsFiltersAC = (payload) => ({
  type: types.SET_ALL_INCIDENTS_FILTERS,
  payload,
});

export const setAllIncidentsSortFieldAC: types.SetAllIncidentsSortFieldAC = (payload) => ({
  type: types.SET_ALL_INCIDENTS_SORT_FIELD,
  payload,
});

export const setAllIncidentsPerPageAC: types.SetAllIncidentsPerPageAC = (payload) => ({
  type: types.SET_ALL_INCIDENTS_PER_PAGE,
  payload,
});

export const setAllIncidentsCurrentPageAC: types.SetAllIncidentsCurrentPageAC = (payload) => ({
  type: types.SET_ALL_INCIDENTS_CURRENT_PAGE,
  payload,
});

export const detailedIncidentRequestAC: types.DetailedIncidentRequestAC = () => ({
  type: types.DETAILED_INCIDENT_REQUEST,
});

export const detailedIncidentRequestSuccessAC: types.DetailedIncidentRequestSuccessAC = (payload) => ({
  type: types.DETAILED_INCIDENT_REQUEST_SUCCESS,
  payload,
});

export const detailedIncidentRequestFailureAC: types.DetailedIncidentRequestFailureAC = () => ({
  type: types.DETAILED_INCIDENT_REQUEST_FAILURE,
});

export const setDetailedIncidentLogPerPageAC: types.SetDetailedIncidentLogPerPageAC = (payload) => ({
  type: types.SET_DETAILED_INCIDENT_LOG_PER_PAGE,
  payload,
});

export const setDetailedIncidentLogCurrentPageAC: types.SetDetailedIncidentLogCurrentPageAC = (payload) => ({
  type: types.SET_DETAILED_INCIDENT_LOG_CURRENT_PAGE,
  payload,
});

export const sendEmailRequestAC: types.SendEmailRequestAC = () => ({
  type: types.SEND_EMAIL_REQUEST,
});

export const sendEmailRequestSuccessAC: types.SendEmailRequestSuccessAC = () => ({
  type: types.SEND_EMAIL_REQUEST_SUCCESS,
});

export const sendEmailRequestFailureAC: types.SendEmailRequestFailedAC = () => ({
  type: types.SEND_EMAIL_REQUEST_FAILED,
});
