import cn from "classnames";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import styles from "./styles.module.css";

export interface TabsConfigModel {
  name: string;
  path: string;
}

interface CardTabsProps {
  tabsConfig: TabsConfigModel[];
  localeObjectName: string;
  activeTab: string;
  resetSortField?: (fieldName: any) => void;
  resetCurrentPage?: (pageNum: number) => void;
  resetFilters?: (emptyFiltersObject: any) => void;
  emptyFiltersObject?: Object;
}

const Tabs = ({
  tabsConfig,
  localeObjectName,
  activeTab,
  resetSortField,
  resetCurrentPage,
  resetFilters,
  emptyFiltersObject,
}: CardTabsProps) => {
  const { t } = useTranslation(localeObjectName);
  const dispatch = useDispatch();

  const handleTabClick = (e: { target: any }) => {
    if (resetSortField) dispatch(resetSortField(""));
    if (resetCurrentPage) dispatch(resetCurrentPage(1));
    if (resetFilters && emptyFiltersObject) dispatch(resetFilters(emptyFiltersObject));
  };

  return (
    <div className={styles.securityTabs}>
      {tabsConfig.map((tab) => (
        <Link
          key={tab.path}
          to={tab.path}
          onClick={handleTabClick}
          className={cn(styles.securityTab, { [styles.securityActiveTab]: tab.name === activeTab })}
          data-testid={tab.name}
        >
          {t(tab.name)}
        </Link>
      ))}
    </div>
  );
};

export default Tabs;
