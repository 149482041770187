import React from "react";
import RecommendationsTable from "./tables/RecommendationsTable";
import VulnerableSoftwareTable from "./tables/VulnerableSoftwareTable";

import styles from "./styles.module.css";

const ProactiveSecurityPageTables = () => {
  return (
    <div className={styles.tablesContainer}>
      <div className={styles.tableItem}>
        <VulnerableSoftwareTable />
      </div>
      <div className={styles.tableItem}>
        <RecommendationsTable />
      </div>
    </div>
  );
};

export default ProactiveSecurityPageTables;
