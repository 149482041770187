import { AppThunk } from "store";
import * as startPageApi from "api/startPage.api";
import * as startPageActions from "./startPage.actions";
import i18n from "../../locales";
import { notificationErrorAC } from "../notification/notification.actions";
import { parseApiErrorMessage } from "utils/helpers";

export const getSecureScoreInfo =
  (selectedContractId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startPageActions.secureScoreRequestAC());
      const { data } = await startPageApi.getSecureScore(selectedContractId);
      dispatch(startPageActions.secureScoreRequestSuccessAC(data.secureScore));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "SECURE SCORE REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:secureScoreRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(startPageActions.secureScoreRequestFailureAC());
    }
  };

export const getStaleDevicesInfo =
  (selectedContractId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startPageActions.staleDevicesRequestAC());
      const { data } = await startPageApi.getStaleDevices(selectedContractId);
      dispatch(startPageActions.staleDevicesRequestSuccessAC(data.staleDevices));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "STALE DEVICES REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:staleDevicesRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(startPageActions.staleDevicesRequestFailureAC());
    }
  };

export const getNoncompliantDevicesInfo =
  (selectedContractId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startPageActions.noncompliantDevicesRequestAC());
      const { data } = await startPageApi.getNoncompliantDevices(selectedContractId);
      dispatch(startPageActions.noncompliantDevicesRequestSuccessAC(data.noncompliantDevices));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "NONCOMPLIANT DEVICES REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:noncompliantDevicesRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(startPageActions.noncompliantDevicesRequestFailureAC());
    }
  };

export const getUnmanagedDevicesInfo =
  (selectedContractId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startPageActions.unmanagedDevicesRequestAC());
      const { data } = await startPageApi.getUnmanagedDevices(selectedContractId);
      dispatch(startPageActions.unmanagedDevicesRequestSuccessAC(data.unmanagedDevices));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "UNMANAGED DEVICES REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:unmanagedDevicesRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(startPageActions.unmanagedDevicesRequestFailureAC());
    }
  };

export const getUsersInfo =
  (selectedContractId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startPageActions.usersRequestAC());
      const { data } = await startPageApi.getUsers(selectedContractId);
      dispatch(startPageActions.usersRequestSuccessAC(data.users));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "USERS REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:usersRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(startPageActions.usersRequestFailureAC());
    }
  };

export const getSecureScoreHistoryDevicesInfo =
  (selectedContractId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startPageActions.secureScoreHistoryRequestAC());
      const { data } = await startPageApi.getSecureScoreHistory(selectedContractId);
      dispatch(startPageActions.secureScoreHistoryRequestSuccessAC(data.secureScoreHistory));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "SECURE SCORE HISTORY REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:secureScoreHistoryRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(startPageActions.secureScoreHistoryRequestFailureAC());
    }
  };

export const getSecureScoreCategorisedDevicesInfo =
  (selectedContractId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startPageActions.secureScoreCategorisedRequestAC());
      const { data } = await startPageApi.getSecureScoreCategorised(selectedContractId);
      dispatch(startPageActions.secureScoreCategorisedRequestSuccessAC(data.secureScoreCategorised));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "SECURE SCORE CATEGORISED REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:secureScoreCategorisedRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(startPageActions.secureScoreCategorisedRequestFailureAC());
    }
  };

export const getIntuneEnrolledDevicesInfo =
  (selectedContractId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startPageActions.intuneEnrolledDevicesRequestAC());
      const { data } = await startPageApi.getIntuneEnrolledDevices(selectedContractId);
      dispatch(startPageActions.intuneEnrolledDevicesRequestSuccessAC(data.intuneEnrolledDevices));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "INTUNE ENROLLED DEVICES REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:intuneEnrolledDevicesRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(startPageActions.intuneEnrolledDevicesRequestFailureAC());
    }
  };

export const getRiskySigninsInfo =
  (selectedContractId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startPageActions.riskySigninsRequestAC());
      const { data } = await startPageApi.getRiskySignins(selectedContractId);
      dispatch(startPageActions.riskySigninsRequestSuccessAC(data.riskySignins));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "RISKY SIGNINS REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:riskySigninsRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(startPageActions.riskySigninsRequestFailureAC());
    }
  };

export const getRiskyUsersInfo =
  (selectedContractId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startPageActions.riskyUsersRequestAC());
      const { data } = await startPageApi.getRiskyUsers(selectedContractId);
      dispatch(startPageActions.riskyUsersRequestSuccessAC(data.riskyUsers));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "RISKY USERS REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:riskyUsersRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(startPageActions.riskyUsersRequestFailureAC());
    }
  };
