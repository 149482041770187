import { SecureScoreModel } from "types/startPageApi.types";

export const composeProgressBarOptions = (secureScoreData: SecureScoreModel) => {
  return {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      backgroundColor: "#2e2256",
      spacing: [8, 0, 0, 0],
      type: "bar",
      height: 30,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },
    tooltip: {
      shared: true,
      padding: 4,
      formatter: function () {
        const chartData = this as any;
        return chartData.points.reverse().map((point: any, index: number) =>
          index === 0
            ? // Target Percentage tooltip
              // `<span style="font-family: Dustin Sans;">
              //       <span>Target:</span>
              //       <span style="color:${point.color}"><b>${secureScoreData.targetPercentage}%</b></span>
              //     </span><br>`
              `<span style="font-family: Dustin Sans;">
                  <span>Score:</span>
                  <span><b>${point.y}%</b></span>
                </span><br>`
            : null
        );
      },
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      bar: {
        animation: {
          duration: 500,
        },
        borderWidth: 0,
        borderRadius: 1,
      },
      series: {
        stacking: "normal",
        groupPadding: 0,
      },
    },
    xAxis: {
      visible: false,
    },
    yAxis: {
      visible: false,
      min: 0,
      max: 100,
    },
    series: [
      { data: [{ y: 100 - secureScoreData.currentPercentage }], color: "#f0f0f0" },
      {
        name: "Score",
        data: [{ y: secureScoreData.currentPercentage }],
        // Color setting depending on Target Percentage
        // color: secureScoreData.currentPercentage >= secureScoreData.targetPercentage ? "#4ef09d" : "#ff5a5a",
        color: "#4ef09d",
      },
    ],
    legend: {
      enabled: false,
    },
  };
};
