import React from "react";
import { Dropdown } from "@skyportal/ui-kit";
import useContractDropdown from "./useContractDropdown";
import { useLocation } from "react-router-dom";

export interface ContractDropdownProps {
  className?: string;
}

const ContractDropdown: React.FC<ContractDropdownProps> = ({ className }) => {
  const { handleChange, options, value } = useContractDropdown();
  const { pathname } = useLocation();

  const isDetailedIncidentPagePath =
    pathname.includes("/security/detection-and-response/incidents/") && pathname.length > 40;

  return (
    <Dropdown
      prefixIcon="user"
      data-testid="contractDropdown"
      onChange={handleChange}
      value={value}
      disabled={!Boolean(value) || isDetailedIncidentPagePath}
      className={className}
      options={options}
    />
  );
};

export default React.memo(ContractDropdown);
