import { SET_CONTRACTS_LIST, SELECT_CONTRACT, SetContractsListAC, SelectContractAC } from "./contracts.types";

export const setContractsList: SetContractsListAC = (payload) => ({
  type: SET_CONTRACTS_LIST,
  payload,
});

export const selectContractAC: SelectContractAC = (payload) => ({
  type: SELECT_CONTRACT,
  payload,
});
