import { RootState } from "../index";
import { ContractModel, ContractsStateSlice } from "./contracts.types";
import { createSelector } from "reselect";
import { contractSelectorsByPath } from "containers/ContractDropdown/useContractDropdown";

export const getContractsSlice = (state: RootState): ContractsStateSlice => state.contracts;

export const getSSContractList = (state: RootState): ContractsStateSlice["SS"] => getContractsSlice(state).SS;
export const getSEContractList = (state: RootState): ContractsStateSlice["SE"] => getContractsSlice(state).SE;
export const getSDContractList = (state: RootState): ContractsStateSlice["SD"] => getContractsSlice(state).SD;

export const getSelectedContract = (state: RootState): ContractsStateSlice["selectedContract"] =>
  getContractsSlice(state).selectedContract;

export const getContractDropdownOptions = (currentPath: string) => {
  const currentContractsSelector = contractSelectorsByPath[currentPath] || getSSContractList;

  return createSelector(currentContractsSelector, (contracts) => {
    return (contracts as ContractModel[]).map((item) => ({
      label: `${item.parentName} (${item.name})`,
      value: item.name as string,
    }));
  });
};
