import { RequestStatus } from "types/common.types";
import {
  IntuneEnrolledDevicesModel,
  NoncompliantDevicesModel,
  RiskySigninModel,
  RiskyUserModel,
  SecureScoreCategorisedModel,
  SecureScoreHistoryModel,
  SecureScoreModel,
  StaleDevicesModel,
  UnmanagedDevicesModel,
  UsersModel,
} from "types/startPageApi.types";

export interface WithRequestStatus {
  requestStatus: RequestStatus;
}

export interface StartPageStateSlice {
  readonly secureScore: SecureScoreModel & WithRequestStatus;
  readonly staleDevices: StaleDevicesModel & WithRequestStatus;
  readonly noncompliantDevices: NoncompliantDevicesModel & WithRequestStatus;
  readonly unmanagedDevices: UnmanagedDevicesModel & WithRequestStatus;
  readonly users: UsersModel & WithRequestStatus;
  readonly secureScoreHistory: { list: SecureScoreHistoryModel[] } & WithRequestStatus;
  readonly secureScoreCategorised: { list: SecureScoreCategorisedModel[] } & WithRequestStatus;
  readonly intuneEnrolledDevices: { list: IntuneEnrolledDevicesModel[] } & WithRequestStatus;
  readonly riskySignins: { list: RiskySigninModel[] } & WithRequestStatus;
  readonly riskyUsers: { list: RiskyUserModel[] } & WithRequestStatus;
}

export const SECURE_SCORE_REQUEST = "SECURE_SCORE_REQUEST";
export type SecureScoreRequestAction = {
  type: typeof SECURE_SCORE_REQUEST;
};
export type SecureScoreRequestAC = () => SecureScoreRequestAction;

export const SECURE_SCORE_REQUEST_SUCCESS = "SECURE_SCORE_REQUEST_SUCCESS";
export type SecureScoreRequestSuccessAction = {
  type: typeof SECURE_SCORE_REQUEST_SUCCESS;
  payload: SecureScoreModel;
};
export type SecureScoreRequestSuccessAC = (payload: SecureScoreModel) => SecureScoreRequestSuccessAction;

export const SECURE_SCORE_REQUEST_FAILURE = "SECURE_SCORE_REQUEST_FAILURE";
export type SecureScoreRequestFailureAction = {
  type: typeof SECURE_SCORE_REQUEST_FAILURE;
};
export type SecureScoreRequestFailureAC = () => SecureScoreRequestFailureAction;

export const STALE_DEVICES_REQUEST = "STALE_DEVICES_REQUEST";
export type StaleDevicesRequestAction = {
  type: typeof STALE_DEVICES_REQUEST;
};
export type StaleDevicesRequestAC = () => StaleDevicesRequestAction;

export const STALE_DEVICES_REQUEST_SUCCESS = "STALE_DEVICES_REQUEST_SUCCESS";
export type StaleDevicesRequestSuccessAction = {
  type: typeof STALE_DEVICES_REQUEST_SUCCESS;
  payload: StaleDevicesModel;
};
export type StaleDevicesRequestSuccessAC = (payload: StaleDevicesModel) => StaleDevicesRequestSuccessAction;

export const STALE_DEVICES_REQUEST_FAILURE = "STALE_DEVICES_REQUEST_FAILURE";
export type StaleDevicesRequestFailureAction = {
  type: typeof STALE_DEVICES_REQUEST_FAILURE;
};
export type StaleDevicesRequestFailureAC = () => StaleDevicesRequestFailureAction;

export const NONCOMPLIANT_DEVICES_REQUEST = "NONCOMPLIANT_DEVICES_REQUEST";
export type NoncompliantDevicesRequestAction = {
  type: typeof NONCOMPLIANT_DEVICES_REQUEST;
};
export type NoncompliantDevicesRequestAC = () => NoncompliantDevicesRequestAction;

export const NONCOMPLIANT_DEVICES_REQUEST_SUCCESS = "NONCOMPLIANT_DEVICES_REQUEST_SUCCESS";
export type NoncompliantDevicesRequestSuccessAction = {
  type: typeof NONCOMPLIANT_DEVICES_REQUEST_SUCCESS;
  payload: NoncompliantDevicesModel;
};
export type NoncompliantDevicesRequestSuccessAC = (
  payload: NoncompliantDevicesModel
) => NoncompliantDevicesRequestSuccessAction;

export const NONCOMPLIANT_DEVICES_REQUEST_FAILURE = "NONCOMPLIANT_DEVICES_REQUEST_FAILURE";
export type NoncompliantDevicesRequestFailureAction = {
  type: typeof NONCOMPLIANT_DEVICES_REQUEST_FAILURE;
};
export type NoncompliantDevicesRequestFailureAC = () => NoncompliantDevicesRequestFailureAction;

export const UNMANAGED_DEVICES_REQUEST = "UNMANAGED_DEVICES_REQUEST";
export type UnmanagedDevicesRequestAction = {
  type: typeof UNMANAGED_DEVICES_REQUEST;
};
export type UnmanagedDevicesRequestAC = () => UnmanagedDevicesRequestAction;

export const UNMANAGED_DEVICES_REQUEST_SUCCESS = "UNMANAGED_DEVICES_REQUEST_SUCCESS";
export type UnmanagedDevicesRequestSuccessAction = {
  type: typeof UNMANAGED_DEVICES_REQUEST_SUCCESS;
  payload: UnmanagedDevicesModel;
};
export type UnmanagedDevicesRequestSuccessAC = (payload: UnmanagedDevicesModel) => UnmanagedDevicesRequestSuccessAction;

export const UNMANAGED_DEVICES_REQUEST_FAILURE = "UNMANAGED_DEVICES_REQUEST_FAILURE";
export type UnmanagedDevicesRequestFailureAction = {
  type: typeof UNMANAGED_DEVICES_REQUEST_FAILURE;
};
export type UnmanagedDevicesRequestFailureAC = () => UnmanagedDevicesRequestFailureAction;

export const USERS_REQUEST = "USERS_REQUEST";
export type UsersRequestAction = {
  type: typeof USERS_REQUEST;
};
export type UsersRequestAC = () => UsersRequestAction;

export const USERS_REQUEST_SUCCESS = "USERS_REQUEST_SUCCESS";
export type UsersRequestSuccessAction = {
  type: typeof USERS_REQUEST_SUCCESS;
  payload: UsersModel;
};
export type UsersRequestSuccessAC = (payload: UsersModel) => UsersRequestSuccessAction;

export const USERS_REQUEST_FAILURE = "USERS_REQUEST_FAILURE";
export type UsersRequestFailureAction = {
  type: typeof USERS_REQUEST_FAILURE;
};
export type UsersRequestFailureAC = () => UsersRequestFailureAction;

export const SECURE_SCORE_HISTORY_REQUEST = "SECURE_SCORE_HISTORY_REQUEST";
export type SecureScoreHistoryRequestAction = {
  type: typeof SECURE_SCORE_HISTORY_REQUEST;
};
export type SecureScoreHistoryRequestAC = () => SecureScoreHistoryRequestAction;

export const SECURE_SCORE_HISTORY_REQUEST_SUCCESS = "SECURE_SCORE_HISTORY_REQUEST_SUCCESS";
export type SecureScoreHistoryRequestSuccessAction = {
  type: typeof SECURE_SCORE_HISTORY_REQUEST_SUCCESS;
  payload: SecureScoreHistoryModel[];
};
export type SecureScoreHistoryRequestSuccessAC = (
  payload: SecureScoreHistoryModel[]
) => SecureScoreHistoryRequestSuccessAction;

export const SECURE_SCORE_HISTORY_REQUEST_FAILURE = "SECURE_SCORE_HISTORY_REQUEST_FAILURE";
export type SecureScoreHistoryRequestFailureAction = {
  type: typeof SECURE_SCORE_HISTORY_REQUEST_FAILURE;
};
export type SecureScoreHistoryRequestFailureAC = () => SecureScoreHistoryRequestFailureAction;

export const SECURE_SCORE_CATEGORISED_REQUEST = "SECURE_SCORE_CATEGORISED_REQUEST";
export type SecureScoreCategorisedRequestAction = {
  type: typeof SECURE_SCORE_CATEGORISED_REQUEST;
};
export type SecureScoreCategorisedRequestAC = () => SecureScoreCategorisedRequestAction;

export const SECURE_SCORE_CATEGORISED_REQUEST_SUCCESS = "SECURE_SCORE_CATEGORISED_REQUEST_SUCCESS";
export type SecureScoreCategorisedRequestSuccessAction = {
  type: typeof SECURE_SCORE_CATEGORISED_REQUEST_SUCCESS;
  payload: SecureScoreCategorisedModel[];
};
export type SecureScoreCategorisedRequestSuccessAC = (
  payload: SecureScoreCategorisedModel[]
) => SecureScoreCategorisedRequestSuccessAction;

export const SECURE_SCORE_CATEGORISED_REQUEST_FAILURE = "SECURE_SCORE_CATEGORISED_REQUEST_FAILURE";
export type SecureScoreCategorisedRequestFailureAction = {
  type: typeof SECURE_SCORE_CATEGORISED_REQUEST_FAILURE;
};
export type SecureScoreCategorisedRequestFailureAC = () => SecureScoreCategorisedRequestFailureAction;

export const INTUNE_ENROLLED_DEVICES_REQUEST = "INTUNE_ENROLLED_DEVICES_REQUEST";
export type IntuneEnrolledDevicesRequestAction = {
  type: typeof INTUNE_ENROLLED_DEVICES_REQUEST;
};
export type IntuneEnrolledDevicesRequestAC = () => IntuneEnrolledDevicesRequestAction;

export const INTUNE_ENROLLED_DEVICES_REQUEST_SUCCESS = "INTUNE_ENROLLED_DEVICES_REQUEST_SUCCESS";
export type IntuneEnrolledDevicesRequestSuccessAction = {
  type: typeof INTUNE_ENROLLED_DEVICES_REQUEST_SUCCESS;
  payload: IntuneEnrolledDevicesModel[];
};
export type IntuneEnrolledDevicesRequestSuccessAC = (
  payload: IntuneEnrolledDevicesModel[]
) => IntuneEnrolledDevicesRequestSuccessAction;

export const INTUNE_ENROLLED_DEVICES_REQUEST_FAILURE = "INTUNE_ENROLLED_DEVICES_REQUEST_FAILURE";
export type IntuneEnrolledDevicesRequestFailureAction = {
  type: typeof INTUNE_ENROLLED_DEVICES_REQUEST_FAILURE;
};
export type IntuneEnrolledDevicesRequestFailureAC = () => IntuneEnrolledDevicesRequestFailureAction;

export const RISKY_SIGNINS_REQUEST = "RISKY_SIGNINS_REQUEST";
export type RiskySigninsRequestAction = {
  type: typeof RISKY_SIGNINS_REQUEST;
};
export type RiskySigninsRequestAC = () => RiskySigninsRequestAction;

export const RISKY_SIGNINS_REQUEST_SUCCESS = "RISKY_SIGNINS_REQUEST_SUCCESS";
export type RiskySigninsRequestSuccessAction = {
  type: typeof RISKY_SIGNINS_REQUEST_SUCCESS;
  payload: RiskySigninModel[];
};
export type RiskySigninsRequestSuccessAC = (payload: RiskySigninModel[]) => RiskySigninsRequestSuccessAction;

export const RISKY_SIGNINS_REQUEST_FAILURE = "RISKY_SIGNINS_REQUEST_FAILURE";
export type RiskySigninsRequestFailureAction = {
  type: typeof RISKY_SIGNINS_REQUEST_FAILURE;
};
export type RiskySigninsRequestFailureAC = () => RiskySigninsRequestFailureAction;

export const RISKY_USERS_REQUEST = "RISKY_USERS_REQUEST";
export type RiskyUsersRequestAction = {
  type: typeof RISKY_USERS_REQUEST;
};
export type RiskyUsersRequestAC = () => RiskyUsersRequestAction;

export const RISKY_USERS_REQUEST_SUCCESS = "RISKY_USERS_REQUEST_SUCCESS";
export type RiskyUsersRequestSuccessAction = {
  type: typeof RISKY_USERS_REQUEST_SUCCESS;
  payload: RiskyUserModel[];
};
export type RiskyUsersRequestSuccessAC = (payload: RiskyUserModel[]) => RiskyUsersRequestSuccessAction;

export const RISKY_USERS_REQUEST_FAILURE = "RISKY_USERS_REQUEST_FAILURE";
export type RiskyUsersRequestFailureAction = {
  type: typeof RISKY_USERS_REQUEST_FAILURE;
};
export type RiskyUsersRequestFailureAC = () => RiskyUsersRequestFailureAction;

export type StartPageAction =
  | SecureScoreRequestAction
  | SecureScoreRequestSuccessAction
  | SecureScoreRequestFailureAction
  | StaleDevicesRequestAction
  | StaleDevicesRequestSuccessAction
  | StaleDevicesRequestFailureAction
  | NoncompliantDevicesRequestAction
  | NoncompliantDevicesRequestSuccessAction
  | NoncompliantDevicesRequestFailureAction
  | UnmanagedDevicesRequestAction
  | UnmanagedDevicesRequestSuccessAction
  | UnmanagedDevicesRequestFailureAction
  | UsersRequestAction
  | UsersRequestSuccessAction
  | UsersRequestFailureAction
  | SecureScoreHistoryRequestAction
  | SecureScoreHistoryRequestSuccessAction
  | SecureScoreHistoryRequestFailureAction
  | SecureScoreCategorisedRequestAction
  | SecureScoreCategorisedRequestSuccessAction
  | SecureScoreCategorisedRequestFailureAction
  | IntuneEnrolledDevicesRequestAction
  | IntuneEnrolledDevicesRequestSuccessAction
  | IntuneEnrolledDevicesRequestFailureAction
  | RiskySigninsRequestAction
  | RiskySigninsRequestSuccessAction
  | RiskySigninsRequestFailureAction
  | RiskyUsersRequestAction
  | RiskyUsersRequestSuccessAction
  | RiskyUsersRequestFailureAction;
