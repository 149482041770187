import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { Button, Card, Icon, Spinner, Table, Tooltip } from "@skyportal/ui-kit";
import { useTranslation } from "react-i18next";
import { RequestStatus } from "types/common.types";
import { proactiveSecurityPageSelectors, proactiveSecurityPageThunks } from "store/proactiveSecurityPage";
import { VulnerableSoftwareModel } from "types/proactiveSecurityApi.types";
import { useThunkDispatch } from "store";
import { contractSelectors } from "store/contracts";
import CardFallback from "containers/CardFallback";

import styles from "../styles.module.css";

const VulnerableSoftwareTable = () => {
  const { t } = useTranslation("proactiveSecurityPage");
  const { list, requestStatus } = useSelector(proactiveSecurityPageSelectors.getVulnerableSoftware);

  const thunkDispatch = useThunkDispatch();
  const selectedContractId = useSelector(contractSelectors.getSelectedContract)?.name as string;
  const reloadData = useCallback(
    () => thunkDispatch(proactiveSecurityPageThunks.getVulnerableSoftwareInfo(selectedContractId)),
    [selectedContractId, thunkDispatch]
  );

  const columnsConfig = useMemo(
    () => [
      {
        key: "name",
        dataIndex: "name",
        title: t("vulnerableSoftwareTableColumns.name"),
        sortDisabled: true,
        width: "40%",
      },
      {
        key: "vendor",
        dataIndex: "vendor",
        title: t("vulnerableSoftwareTableColumns.vendor"),
        sortDisabled: true,
        width: "15%",
      },
      {
        key: "weaknesses",
        dataIndex: "weaknesses",
        title: t("vulnerableSoftwareTableColumns.weaknesses"),
        sortDisabled: true,
        width: "15%",
      },
      {
        key: "threats",
        dataIndex: "threats",
        title: t("vulnerableSoftwareTableColumns.threats"),
        sortDisabled: true,
        width: "15%",
        render: (_: undefined, item: VulnerableSoftwareModel) => {
          return (
            <>
              <Tooltip
                title={
                  item.activeAlert ? t("vulnerableSoftwareActiveAlertText") : t("vulnerableSoftwareInactiveAlertText")
                }
                classes={{ tooltip: styles.tooltip }}
              >
                <Button type="tertiary" size="small" className={styles.threatButton}>
                  <Icon
                    type="fontawesome"
                    icon="crosshairs"
                    className={styles.threatIcon}
                    color={item.activeAlert ? "#000000" : "#d8d8d8"}
                  />
                </Button>
              </Tooltip>
              <Tooltip
                title={
                  item.publicExploit
                    ? t("vulnerableSoftwarePublicExploitText")
                    : t("vulnerableSoftwareNoPublicExploitText")
                }
                classes={{ tooltip: styles.tooltip }}
              >
                <Button type="tertiary" size="small" className={styles.threatButton}>
                  <Icon
                    type="fontawesome"
                    icon="bug"
                    className={styles.threatIcon}
                    color={item.publicExploit ? "#e32910" : "#d8d8d8"}
                  />
                </Button>
              </Tooltip>
            </>
          );
        },
      },
      {
        key: "exposedDevices",
        dataIndex: "exposedDevices",
        title: t("vulnerableSoftwareTableColumns.exposedDevices"),
        sortDisabled: true,
        width: "15%",
        render: (_: undefined, item: VulnerableSoftwareModel) =>
          `${item.exposedDevicesCount} / ${item.totalDevicesCount}`,
      },
    ],
    [t]
  );

  return (
    <Card title={t("vulnerableSoftwareTableTitle")} className={styles.card} data-testid="vulnerableSoftwareTable">
      <div className={styles.cardContent}>
        {requestStatus === RequestStatus.PENDING && <Spinner show />}
        {requestStatus === RequestStatus.SUCCESS && (
          <div className={styles.securityTableBlock}>
            <Table dataSource={list} rowKey="vulnerableSoftware" columns={columnsConfig} />
          </div>
        )}
        {requestStatus === RequestStatus.FAILURE && <CardFallback onReload={reloadData} />}
      </div>
    </Card>
  );
};

export default VulnerableSoftwareTable;
