import * as types from "./startPage.types";

export const secureScoreRequestAC: types.SecureScoreRequestAC = () => ({
  type: types.SECURE_SCORE_REQUEST,
});

export const secureScoreRequestSuccessAC: types.SecureScoreRequestSuccessAC = (payload) => ({
  type: types.SECURE_SCORE_REQUEST_SUCCESS,
  payload,
});

export const secureScoreRequestFailureAC: types.SecureScoreRequestFailureAC = () => ({
  type: types.SECURE_SCORE_REQUEST_FAILURE,
});

export const staleDevicesRequestAC: types.StaleDevicesRequestAC = () => ({
  type: types.STALE_DEVICES_REQUEST,
});

export const staleDevicesRequestSuccessAC: types.StaleDevicesRequestSuccessAC = (payload) => ({
  type: types.STALE_DEVICES_REQUEST_SUCCESS,
  payload,
});

export const staleDevicesRequestFailureAC: types.StaleDevicesRequestFailureAC = () => ({
  type: types.STALE_DEVICES_REQUEST_FAILURE,
});

export const noncompliantDevicesRequestAC: types.NoncompliantDevicesRequestAC = () => ({
  type: types.NONCOMPLIANT_DEVICES_REQUEST,
});

export const noncompliantDevicesRequestSuccessAC: types.NoncompliantDevicesRequestSuccessAC = (payload) => ({
  type: types.NONCOMPLIANT_DEVICES_REQUEST_SUCCESS,
  payload,
});

export const noncompliantDevicesRequestFailureAC: types.NoncompliantDevicesRequestFailureAC = () => ({
  type: types.NONCOMPLIANT_DEVICES_REQUEST_FAILURE,
});

export const unmanagedDevicesRequestAC: types.UnmanagedDevicesRequestAC = () => ({
  type: types.UNMANAGED_DEVICES_REQUEST,
});

export const unmanagedDevicesRequestSuccessAC: types.UnmanagedDevicesRequestSuccessAC = (payload) => ({
  type: types.UNMANAGED_DEVICES_REQUEST_SUCCESS,
  payload,
});

export const unmanagedDevicesRequestFailureAC: types.UnmanagedDevicesRequestFailureAC = () => ({
  type: types.UNMANAGED_DEVICES_REQUEST_FAILURE,
});

export const usersRequestAC: types.UsersRequestAC = () => ({
  type: types.USERS_REQUEST,
});

export const usersRequestSuccessAC: types.UsersRequestSuccessAC = (payload) => ({
  type: types.USERS_REQUEST_SUCCESS,
  payload,
});

export const usersRequestFailureAC: types.UsersRequestFailureAC = () => ({
  type: types.USERS_REQUEST_FAILURE,
});

export const secureScoreHistoryRequestAC: types.SecureScoreHistoryRequestAC = () => ({
  type: types.SECURE_SCORE_HISTORY_REQUEST,
});

export const secureScoreHistoryRequestSuccessAC: types.SecureScoreHistoryRequestSuccessAC = (payload) => ({
  type: types.SECURE_SCORE_HISTORY_REQUEST_SUCCESS,
  payload,
});

export const secureScoreHistoryRequestFailureAC: types.SecureScoreHistoryRequestFailureAC = () => ({
  type: types.SECURE_SCORE_HISTORY_REQUEST_FAILURE,
});

export const secureScoreCategorisedRequestAC: types.SecureScoreCategorisedRequestAC = () => ({
  type: types.SECURE_SCORE_CATEGORISED_REQUEST,
});

export const secureScoreCategorisedRequestSuccessAC: types.SecureScoreCategorisedRequestSuccessAC = (payload) => ({
  type: types.SECURE_SCORE_CATEGORISED_REQUEST_SUCCESS,
  payload,
});

export const secureScoreCategorisedRequestFailureAC: types.SecureScoreCategorisedRequestFailureAC = () => ({
  type: types.SECURE_SCORE_CATEGORISED_REQUEST_FAILURE,
});

export const intuneEnrolledDevicesRequestAC: types.IntuneEnrolledDevicesRequestAC = () => ({
  type: types.INTUNE_ENROLLED_DEVICES_REQUEST,
});

export const intuneEnrolledDevicesRequestSuccessAC: types.IntuneEnrolledDevicesRequestSuccessAC = (payload) => ({
  type: types.INTUNE_ENROLLED_DEVICES_REQUEST_SUCCESS,
  payload,
});

export const intuneEnrolledDevicesRequestFailureAC: types.IntuneEnrolledDevicesRequestFailureAC = () => ({
  type: types.INTUNE_ENROLLED_DEVICES_REQUEST_FAILURE,
});

export const riskySigninsRequestAC: types.RiskySigninsRequestAC = () => ({
  type: types.RISKY_SIGNINS_REQUEST,
});

export const riskySigninsRequestSuccessAC: types.RiskySigninsRequestSuccessAC = (payload) => ({
  type: types.RISKY_SIGNINS_REQUEST_SUCCESS,
  payload,
});

export const riskySigninsRequestFailureAC: types.RiskySigninsRequestFailureAC = () => ({
  type: types.RISKY_SIGNINS_REQUEST_FAILURE,
});

export const riskyUsersRequestAC: types.RiskyUsersRequestAC = () => ({
  type: types.RISKY_USERS_REQUEST,
});

export const riskyUsersRequestSuccessAC: types.RiskyUsersRequestSuccessAC = (payload) => ({
  type: types.RISKY_USERS_REQUEST_SUCCESS,
  payload,
});

export const riskyUsersRequestFailureAC: types.RiskyUsersRequestFailureAC = () => ({
  type: types.RISKY_USERS_REQUEST_FAILURE,
});
