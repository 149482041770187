import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useThunkDispatch } from "store";
import { contractSelectors } from "store/contracts";
import { proactiveSecurityPageSelectors, proactiveSecurityPageThunks } from "store/proactiveSecurityPage";
import { startPageThunks } from "store/startPage";

const useFetchProactiveSecurityPageData = () => {
  const thunkDispatch = useThunkDispatch();
  const contractsSE = useSelector(contractSelectors.getSEContractList);
  const selectedContractId = useSelector(contractSelectors.getSelectedContract)?.name as string;

  const isValidContractChosen = !!contractsSE.find((contract) => contract?.name === selectedContractId);

  useEffect(() => {
    if (selectedContractId && isValidContractChosen) {
      thunkDispatch(proactiveSecurityPageThunks.getExposureScoreInfo(selectedContractId));
      thunkDispatch(proactiveSecurityPageThunks.getExposureDistributionInfo(selectedContractId));
      thunkDispatch(proactiveSecurityPageThunks.getExpiringCertificatesInfo(selectedContractId));
      thunkDispatch(proactiveSecurityPageThunks.getDeviceScoreHistoryInfo(selectedContractId));
      thunkDispatch(proactiveSecurityPageThunks.getVulnerableSoftwareInfo(selectedContractId));
      thunkDispatch(proactiveSecurityPageThunks.getRecommendationsInfo(selectedContractId));

      thunkDispatch(startPageThunks.getSecureScoreInfo(selectedContractId));
      thunkDispatch(startPageThunks.getStaleDevicesInfo(selectedContractId));
      thunkDispatch(startPageThunks.getNoncompliantDevicesInfo(selectedContractId));
      thunkDispatch(startPageThunks.getUnmanagedDevicesInfo(selectedContractId));
      thunkDispatch(startPageThunks.getUsersInfo(selectedContractId));
      thunkDispatch(startPageThunks.getSecureScoreHistoryDevicesInfo(selectedContractId));
      thunkDispatch(startPageThunks.getSecureScoreCategorisedDevicesInfo(selectedContractId));
      thunkDispatch(startPageThunks.getIntuneEnrolledDevicesInfo(selectedContractId));
      thunkDispatch(startPageThunks.getRiskySigninsInfo(selectedContractId));
      thunkDispatch(startPageThunks.getRiskyUsersInfo(selectedContractId));
    }
  }, [thunkDispatch, selectedContractId, isValidContractChosen]);

  return useSelector(proactiveSecurityPageSelectors.getSlice);
};

export default useFetchProactiveSecurityPageData;
