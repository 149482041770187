import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "layouts/PageLayout";
import PageHeader from "containers/PageHeader/PageHeader";
import useFetchProactiveSecurityPageData from "hooks/useFetchProactiveSecurityPageData";
import ProactiveSecurityPageCharts from "components/ProactiveSecurityPageCharts";
import ProactiveSecurityPageTables from "components/ProactiveSecurityPageTables";
import Tabs, { TabsConfigModel } from "containers/Tabs/Tabs";
import { useDispatch } from "react-redux";
import { Redirect, Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import InfoBoxCards from "components/InfoBoxCards";
import StartPageCharts from "components/StartPageCharts";

const tabsConfig: TabsConfigModel[] = [
  { name: "endpointsTab", path: "/security/proactive-security/endpoints" },
  { name: "identityTab", path: "/security/proactive-security/identity" },
];

const ProactiveSecurityPage: React.FC = () => {
  const { t } = useTranslation("proactiveSecurityPage");
  const dispatch = useDispatch();
  useFetchProactiveSecurityPageData();

  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const [activeTab, setActiveTab] = useState<string>(
    tabsConfig.find((tab) => pathname.startsWith(tab.path))?.name || tabsConfig[0].name
  );

  useEffect(() => {
    setActiveTab(tabsConfig.find((tab) => pathname.startsWith(tab.path))?.name || tabsConfig[0].name);
  }, [dispatch, pathname]);

  return (
    <PageLayout>
      <PageHeader title={t("title")} />
      <Tabs tabsConfig={tabsConfig} localeObjectName="proactiveSecurityPage" activeTab={activeTab} />
      <Switch>
        <Route path={`${path}/endpoints`} exact>
          <ProactiveSecurityPageCharts />
          <ProactiveSecurityPageTables />
        </Route>
        <Route path={`${path}/identity`} exact>
          <InfoBoxCards />
          <StartPageCharts />
        </Route>
        <Route path="*">
          <Redirect to={`${path}/endpoints`} />
        </Route>
      </Switch>
    </PageLayout>
  );
};

export default ProactiveSecurityPage;
