import Highcharts from "highcharts";
import { SecureScoreHistoryModel } from "types/startPageApi.types";

export const composeChartOptions = (secureScoreHistoryData: SecureScoreHistoryModel[]) => {
  return {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      borderRadius: 20,
      spacingTop: 20,
      plotShadow: false,
      type: "areaspline",
      height: 300,
    },
    colors: ["#44d9fc", "#4ef09d", "#c48df7", "#e59a7f", "#ff5a5a", "#c4c4c4"],
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },
    tooltip: {
      split: true,
      pointFormat: "{series.name}: <b>{point.y}%</b><br>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    yAxis: {
      title: null,
      max: 100,
      labels: {
        format: "{value}%",
      },
      gridLineWidth: 0,
    },
    xAxis: {
      title: null,
      type: "datetime",
      gridLineWidth: 1,
    },
    plotOptions: {
      areaspline: {
        animation: {
          duration: 1500,
        },
        marker: {
          enabled: false,
        },
      },
    },
    series: [
      {
        name: "Score",
        data: secureScoreHistoryData.map((item) => {
          const timestamp = Number(new Date(item.date));
          return { x: timestamp, y: item.score };
        }),
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [0, new Highcharts.Color("#44d9fc").setOpacity(1).get("rgba")],
            [1, new Highcharts.Color("#44d9fc").setOpacity(0.2).get("rgba")],
          ],
        },
      },
    ],
    legend: {
      enabled: false,
    },
  };
};
