import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, Spinner, TextInput } from "@skyportal/ui-kit";
import { contractSelectors } from "store/contracts";
import { RequestStatus } from "types/common.types";
import { isValidEmail } from "utils/validation";
import { getSendEmailRequestStatus } from "store/detectAndResponsePage/detectAndResponsePage.selectors";
import { sendIncidentReportViaEmailRequest } from "store/detectAndResponsePage/detectAndResponsePage.thunk.actions";

import styles from "./styles.module.css";

export interface SendModalProps {
  open: boolean;
  onClose: () => void;
  incidentId: string;
  reportParams?: object;
}

const SendModal = ({ open, onClose, reportParams = {}, incidentId }: SendModalProps) => {
  const onCloseRef = useRef(onClose);
  const { t } = useTranslation("securitySendReportModal");
  const selectedContractId = useSelector(contractSelectors.getSelectedContract)?.name as string;
  const mailReportRequestStatus = useSelector(getSendEmailRequestStatus);
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [emailValidMsg, setEmailValidMsg] = useState("");

  const handleEmailChange = (event: any) => setEmail(event.target.value);

  const handleSendBtnClick = () => {
    if (!isValidEmail(email)) {
      setEmailValidMsg(t("invalidEmail"));
      return;
    }

    dispatch(
      sendIncidentReportViaEmailRequest(selectedContractId, incidentId, {
        ...reportParams,
        accept: "pdf",
        email,
      })
    );
  };

  useEffect(() => {
    if (mailReportRequestStatus === RequestStatus.SUCCESS) {
      onCloseRef.current();
    }
  }, [mailReportRequestStatus]);

  useEffect(() => {
    setEmailValidMsg("");
  }, [email]);

  return (
    <Modal open={open} onClose={onClose} size="small" title={t("title")} data-testid="sendModal">
      <Spinner show={mailReportRequestStatus === RequestStatus.PENDING} />
      <div className={styles.securitySendModalContent}>
        <label className={styles.controlRow}>
          <span className={styles.controlLabel}>{t("Email")}</span>
          <TextInput
            data-testid="sendModal-email"
            name="email"
            value={email}
            onChange={handleEmailChange}
            placeholder="example@gmail.com"
            className={styles.input}
          />
          <div className={styles.errMsg} data-testid="sendModal-errorMsg">
            {emailValidMsg}
          </div>
        </label>
      </div>
      <div className={styles.cardFooter}>
        <Button data-testid="sendModal-closeBtn" type="secondary" onClick={onClose}>
          {t("common:Cancel")}
        </Button>
        <Button data-testid="sendModal-sendBtn" onClick={handleSendBtnClick} type="primary">
          <span>{t("common:Send")}</span>
        </Button>
      </div>
    </Modal>
  );
};

export default SendModal;
