import { RootState } from "../index";
import { StartPageStateSlice } from "./startPage.types";

export const getSlice = (state: RootState): StartPageStateSlice => state.startPage;

export const getSecureScore = (state: RootState) => getSlice(state).secureScore;
export const getStaleDevices = (state: RootState) => getSlice(state).staleDevices;
export const getNoncompliantDevices = (state: RootState) => getSlice(state).noncompliantDevices;
export const getUnmanegedDevices = (state: RootState) => getSlice(state).unmanagedDevices;
export const getUsers = (state: RootState) => getSlice(state).users;
export const getSecureScoreHistory = (state: RootState) => getSlice(state).secureScoreHistory;
export const getSecureScoreCategorised = (state: RootState) => getSlice(state).secureScoreCategorised;
export const getIntuneEnrolledDevices = (state: RootState) => getSlice(state).intuneEnrolledDevices;
export const getRiskySignins = (state: RootState) => getSlice(state).riskySignins;
export const getRiskyUsers = (state: RootState) => getSlice(state).riskyUsers;
