import { ExposureScoreModel } from "types/proactiveSecurityApi.types";

export const lowLevelDangerColor = "#ff6e5a";
export const mediumLevelDangerColor = "#e32910";
export const highLevelDangerColor = "#9b2110";
export const extraHighLevelDangerColor = "#67160b";

const getColorByScore = (score: number) => {
  if (score >= 0 && score <= 29) {
    return lowLevelDangerColor;
  } else if (score >= 30 && score <= 69) {
    return mediumLevelDangerColor;
  }
  return highLevelDangerColor;
};

export const composeExposureScoreChartOptions = ({ currentScore }: ExposureScoreModel) => {
  return {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      borderRadius: 20,
      plotShadow: false,
      type: "pie",
      height: 350,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },
    accessibility: {
      point: {
        valueSuffix: "pcs",
      },
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },
    series: [
      {
        innerSize: "60%",
        startAngle: -90,
        endAngle: 90,
        data: [
          { y: currentScore, color: getColorByScore(currentScore) },
          { y: 100 - currentScore, color: "#c4c4c4" },
        ],
        center: ["50%", "60%"],
        size: "105%",
      },
    ],
    legend: {
      enabled: true,
    },
  };
};
