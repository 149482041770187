import { InfoBoxesCardConfigType, InfoBoxesCardDataType, InfoBoxesCardType } from "./types";
import { startPageSelectors, startPageThunks } from "store/startPage";

export const SECURE_SCORE_VARIANT = "SECURE_SCORE_VARIANT";
export const STALE_DEVICES_VARIANT = "STALE_DEVICES_VARIANT";
export const NONCOMPLIANT_DEVICES_VARIANT = "NONCOMPLIANT_DEVICES_VARIANT";
export const UNMANAGED_DEVICES_VARIANT = "UNMANAGED_DEVICES_VARIANT";
export const USERS_VARIANT = "USERS_VARIANT";

export const INFO_BOXES_VARIANTS: Array<InfoBoxesCardType> = [
  SECURE_SCORE_VARIANT,
  STALE_DEVICES_VARIANT,
  NONCOMPLIANT_DEVICES_VARIANT,
  UNMANAGED_DEVICES_VARIANT,
  USERS_VARIANT,
];

export const InfoBoxesCardData: InfoBoxesCardDataType = {
  [SECURE_SCORE_VARIANT]: ["currentPercentage", "currentPoints", "totalPoints"],
  [STALE_DEVICES_VARIANT]: ["count"],
  [NONCOMPLIANT_DEVICES_VARIANT]: ["count"],
  [UNMANAGED_DEVICES_VARIANT]: ["count"],
  [USERS_VARIANT]: ["total", "mfaRegistered"],
};

export const InfoBoxesCardsConfig: Record<InfoBoxesCardType, InfoBoxesCardConfigType> = {
  [SECURE_SCORE_VARIANT]: {
    selector: startPageSelectors.getSecureScore,
    thunk: startPageThunks.getSecureScoreInfo,
    labelPhrase: "infoBoxesBlock.secureScoreLabel",
    subLabelPhrase: "infoBoxesBlock.secureScoreSubLabel",
    style: "secureScoreVariant",
    icon: "shield-halved",
  },
  [STALE_DEVICES_VARIANT]: {
    selector: startPageSelectors.getStaleDevices,
    thunk: startPageThunks.getStaleDevicesInfo,
    labelPhrase: "infoBoxesBlock.staleDevicesLabel",
    subLabelPhrase: "infoBoxesBlock.staleDevicesSubLabel",
    style: "staleDevicesVariant",
    icon: "mobile-retro",
  },
  [NONCOMPLIANT_DEVICES_VARIANT]: {
    selector: startPageSelectors.getNoncompliantDevices,
    thunk: startPageThunks.getNoncompliantDevicesInfo,
    labelPhrase: "infoBoxesBlock.noncompliantDevicesLabel",
    subLabelPhrase: "infoBoxesBlock.noncompliantDevicesSubLabel",
    style: "noncompliantDevicesVariant",
    icon: "circle-xmark",
  },
  [UNMANAGED_DEVICES_VARIANT]: {
    selector: startPageSelectors.getUnmanegedDevices,
    thunk: startPageThunks.getUnmanagedDevicesInfo,
    labelPhrase: "infoBoxesBlock.unmanagedDevicesLabel",
    subLabelPhrase: "infoBoxesBlock.unmanagedDevicesSubLabel",
    style: "unmanagedDevicesVariant",
    icon: "person-circle-exclamation",
  },
  [USERS_VARIANT]: {
    selector: startPageSelectors.getUsers,
    thunk: startPageThunks.getUsersInfo,
    labelPhrase: "infoBoxesBlock.usersLabel",
    subLabelPhrase: "infoBoxesBlock.usersSubLabel",
    style: "usersVariant",
    icon: "users",
  },
};
