import { RequestStatus } from "types/common.types";
import {
  DeviceScoreHistoryModel,
  ExpiringCertificatesModel,
  ExposureDistributionModel,
  ExposureScoreModel,
  RecommendationsModel,
  VulnerableSoftwareModel,
} from "types/proactiveSecurityApi.types";

export interface WithRequestStatus {
  requestStatus: RequestStatus;
}

export interface EnhancedProtectionPageStateSlice {
  readonly exposureScore: ExposureScoreModel & WithRequestStatus;
  readonly exposureDistribution: ExposureDistributionModel & WithRequestStatus;
  readonly expiringCertificates: ExpiringCertificatesModel & WithRequestStatus;
  readonly deviceScoreHistory: { list: DeviceScoreHistoryModel[] } & WithRequestStatus;
  readonly vulnerableSoftware: { list: VulnerableSoftwareModel[] } & WithRequestStatus;
  readonly recommendations: { list: RecommendationsModel[] } & WithRequestStatus;
}

export const EXPOSURE_SCORE_REQUEST = "EXPOSURE_SCORE_REQUEST";
export type ExposureScoreRequestAction = {
  type: typeof EXPOSURE_SCORE_REQUEST;
};
export type ExposureScoreRequestAC = () => ExposureScoreRequestAction;

export const EXPOSURE_SCORE_REQUEST_SUCCESS = "EXPOSURE_SCORE_REQUEST_SUCCESS";
export type ExposureScoreRequestSuccessAction = {
  type: typeof EXPOSURE_SCORE_REQUEST_SUCCESS;
  payload: ExposureScoreModel;
};
export type ExposureScoreRequestSuccessAC = (payload: ExposureScoreModel) => ExposureScoreRequestSuccessAction;

export const EXPOSURE_SCORE_REQUEST_FAILURE = "EXPOSURE_SCORE_REQUEST_FAILURE";
export type ExposureScoreRequestFailureAction = {
  type: typeof EXPOSURE_SCORE_REQUEST_FAILURE;
};
export type ExposureScoreRequestFailureAC = () => ExposureScoreRequestFailureAction;

export const EXPOSURE_DISTRIBUTION_REQUEST = "EXPOSURE_DISTRIBUTION_REQUEST";
export type ExposureDistributionRequestAction = {
  type: typeof EXPOSURE_DISTRIBUTION_REQUEST;
};
export type ExposureDistributionRequestAC = () => ExposureDistributionRequestAction;

export const EXPOSURE_DISTRIBUTION_REQUEST_SUCCESS = "EXPOSURE_DISTRIBUTION_REQUEST_SUCCESS";
export type ExposureDistributionRequestSuccessAction = {
  type: typeof EXPOSURE_DISTRIBUTION_REQUEST_SUCCESS;
  payload: ExposureDistributionModel;
};
export type ExposureDistributionRequestSuccessAC = (
  payload: ExposureDistributionModel
) => ExposureDistributionRequestSuccessAction;

export const EXPOSURE_DISTRIBUTION_REQUEST_FAILURE = "EXPOSURE_DISTRIBUTION_REQUEST_FAILURE";
export type ExposureDistributionRequestFailureAction = {
  type: typeof EXPOSURE_DISTRIBUTION_REQUEST_FAILURE;
};
export type ExposureDistributionRequestFailureAC = () => ExposureDistributionRequestFailureAction;

export const EXPIRING_CERTIFICATES_REQUEST = "EXPIRING_CERTIFICATES_REQUEST";
export type ExpiringCertificatesRequestAction = {
  type: typeof EXPIRING_CERTIFICATES_REQUEST;
};
export type ExpiringCertificatesRequestAC = () => ExpiringCertificatesRequestAction;

export const EXPIRING_CERTIFICATES_REQUEST_SUCCESS = "EXPIRING_CERTIFICATES_REQUEST_SUCCESS";
export type ExpiringCertificatesRequestSuccessAction = {
  type: typeof EXPIRING_CERTIFICATES_REQUEST_SUCCESS;
  payload: ExpiringCertificatesModel;
};
export type ExpiringCertificatesRequestSuccessAC = (
  payload: ExpiringCertificatesModel
) => ExpiringCertificatesRequestSuccessAction;

export const EXPIRING_CERTIFICATES_REQUEST_FAILURE = "EXPIRING_CERTIFICATES_REQUEST_FAILURE";
export type ExpiringCertificatesRequestFailureAction = {
  type: typeof EXPIRING_CERTIFICATES_REQUEST_FAILURE;
};
export type ExpiringCertificatesRequestFailureAC = () => ExpiringCertificatesRequestFailureAction;

export const DEVICE_SCORE_HISTORY_REQUEST = "DEVICE_SCORE_HISTORY_REQUEST";
export type DeviceScoreHistoryRequestAction = {
  type: typeof DEVICE_SCORE_HISTORY_REQUEST;
};
export type DeviceScoreHistoryRequestAC = () => DeviceScoreHistoryRequestAction;

export const DEVICE_SCORE_HISTORY_REQUEST_SUCCESS = "DEVICE_SCORE_HISTORY_REQUEST_SUCCESS";
export type DeviceScoreHistoryRequestSuccessAction = {
  type: typeof DEVICE_SCORE_HISTORY_REQUEST_SUCCESS;
  payload: DeviceScoreHistoryModel[];
};
export type DeviceScoreHistoryRequestSuccessAC = (
  payload: DeviceScoreHistoryModel[]
) => DeviceScoreHistoryRequestSuccessAction;

export const DEVICE_SCORE_HISTORY_REQUEST_FAILURE = "DEVICE_SCORE_HISTORY_REQUEST_FAILURE";
export type DeviceScoreHistoryRequestFailureAction = {
  type: typeof DEVICE_SCORE_HISTORY_REQUEST_FAILURE;
};
export type DeviceScoreHistoryRequestFailureAC = () => DeviceScoreHistoryRequestFailureAction;

export const VULNERABLE_SOFTWARE_REQUEST = "VULNERABLE_SOFTWARE_REQUEST";
export type VulnerableSoftwareRequestAction = {
  type: typeof VULNERABLE_SOFTWARE_REQUEST;
};
export type VulnerableSoftwareRequestAC = () => VulnerableSoftwareRequestAction;

export const VULNERABLE_SOFTWARE_REQUEST_SUCCESS = "VULNERABLE_SOFTWARE_REQUEST_SUCCESS";
export type VulnerableSoftwareRequestSuccessAction = {
  type: typeof VULNERABLE_SOFTWARE_REQUEST_SUCCESS;
  payload: VulnerableSoftwareModel[];
};
export type VulnerableSoftwareRequestSuccessAC = (
  payload: VulnerableSoftwareModel[]
) => VulnerableSoftwareRequestSuccessAction;

export const VULNERABLE_SOFTWARE_REQUEST_FAILURE = "VULNERABLE_SOFTWARE_REQUEST_FAILURE";
export type VulnerableSoftwareRequestFailureAction = {
  type: typeof VULNERABLE_SOFTWARE_REQUEST_FAILURE;
};
export type VulnerableSoftwareRequestFailureAC = () => VulnerableSoftwareRequestFailureAction;

export const RECOMMENDATIONS_REQUEST = "RECOMMENDATIONS_REQUEST";
export type RecommendationsRequestAction = {
  type: typeof RECOMMENDATIONS_REQUEST;
};
export type RecommendationsRequestAC = () => RecommendationsRequestAction;

export const RECOMMENDATIONS_REQUEST_SUCCESS = "RECOMMENDATIONS_REQUEST_SUCCESS";
export type RecommendationsRequestSuccessAction = {
  type: typeof RECOMMENDATIONS_REQUEST_SUCCESS;
  payload: RecommendationsModel[];
};
export type RecommendationsRequestSuccessAC = (payload: RecommendationsModel[]) => RecommendationsRequestSuccessAction;

export const RECOMMENDATIONS_REQUEST_FAILURE = "RECOMMENDATIONS_REQUEST_FAILURE";
export type RecommendationsRequestFailureAction = {
  type: typeof RECOMMENDATIONS_REQUEST_FAILURE;
};
export type RecommendationsRequestFailureAC = () => RecommendationsRequestFailureAction;

export type EnhancedProtectionPageAction =
  | ExposureScoreRequestAction
  | ExposureScoreRequestSuccessAction
  | ExposureScoreRequestFailureAction
  | ExposureDistributionRequestAction
  | ExposureDistributionRequestSuccessAction
  | ExposureDistributionRequestFailureAction
  | ExpiringCertificatesRequestAction
  | ExpiringCertificatesRequestSuccessAction
  | ExpiringCertificatesRequestFailureAction
  | DeviceScoreHistoryRequestAction
  | DeviceScoreHistoryRequestSuccessAction
  | DeviceScoreHistoryRequestFailureAction
  | VulnerableSoftwareRequestAction
  | VulnerableSoftwareRequestSuccessAction
  | VulnerableSoftwareRequestFailureAction
  | RecommendationsRequestAction
  | RecommendationsRequestSuccessAction
  | RecommendationsRequestFailureAction;
