import { securityApiClient } from "./clients";
import {
  DeviceScoreHistoryResponse,
  ExpiringCertificatesResponse,
  ExposureDistributionResponse,
  ExposureScoreResponse,
  RecommendationsResponse,
  VulnerableSoftwareResponse,
} from "types/proactiveSecurityApi.types";

export const getExposureScore = (contractId: string) =>
  securityApiClient.request<ExposureScoreResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/security/exposure-score`,
  });

export const getExposureDistribution = (contractId: string) =>
  securityApiClient.request<ExposureDistributionResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/security/exposure-distribution`,
  });

export const getExpiringCertificates = (contractId: string) =>
  securityApiClient.request<ExpiringCertificatesResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/security/expiring-certificates`,
  });

export const getDeviceScoreHistory = (contractId: string) =>
  securityApiClient.request<DeviceScoreHistoryResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/security/device-score-history`,
  });

export const getVulnerableSoftware = (contractId: string) =>
  securityApiClient.request<VulnerableSoftwareResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/security/vulnerable-software`,
  });

export const getRecommendations = (contractId: string) =>
  securityApiClient.request<RecommendationsResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/security/recommendations`,
  });
