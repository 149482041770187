import { AppThunk } from "store";
import * as detectAndResponsePageApi from "api/detectAndResponsePage.api";
import * as detectAndResponsePageActions from "./detectAndResponsePage.actions";
import { notificationAC, notificationErrorAC } from "store/notification/notification.actions";
import i18n from "locales";
import { parseApiErrorMessage } from "utils/helpers";
import { emptyDetailedIncident } from "./detectAndResponsePage.reducer";

export const getUnresolvedIncidentInfo =
  (selectedContractId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(detectAndResponsePageActions.unresolvedIncidentsRequestAC());
      const { data } = await detectAndResponsePageApi.getUnresolvedIncidents(selectedContractId);
      dispatch(detectAndResponsePageActions.unresolvedIncidentsRequestSuccessAC(data.unresolvedIncidents));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "UNRESOLVED INCIDENTS REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:unresolvedIncidentRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(detectAndResponsePageActions.unresolvedIncidentsRequestFailureAC());
    }
  };

export const getIncidentSeverityInfo =
  (selectedContractId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(detectAndResponsePageActions.incidentSeverityRequestAC());
      const { data } = await detectAndResponsePageApi.getIncidentSeverity(selectedContractId);
      dispatch(detectAndResponsePageActions.incidentSeverityRequestSuccessAC(data.incidentSeverity));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "INCIDENT SEVERITY REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:incidentSeverityRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(detectAndResponsePageActions.incidentSeverityRequestFailureAC());
    }
  };

export const getIncidentStatusInfo =
  (selectedContractId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(detectAndResponsePageActions.incidentStatusRequestAC());
      const { data } = await detectAndResponsePageApi.getIncidentStatus(selectedContractId);
      dispatch(detectAndResponsePageActions.incidentStatusRequestSuccessAC(data.incidentStatus));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "INCIDENT STATUS REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:incidentStatusRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(detectAndResponsePageActions.incidentStatusRequestFailureAC());
    }
  };

export const getTop5IncidentsInfo =
  (selectedContractId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(detectAndResponsePageActions.top5IncidentsRequestAC());
      const { data } = await detectAndResponsePageApi.getIncidents(selectedContractId, 5);
      dispatch(detectAndResponsePageActions.top5IncidentsRequestSuccessAC(data.incidents));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "TOP 5 INCIDENTS REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:top5IncidentsRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(detectAndResponsePageActions.top5IncidentsRequestFailureAC());
    }
  };

export const getAllIncidentsInfo =
  (selectedContractId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(detectAndResponsePageActions.allIncidentsRequestAC());
      const { data } = await detectAndResponsePageApi.getIncidents(selectedContractId);
      dispatch(detectAndResponsePageActions.allIncidentsRequestSuccessAC(data.incidents));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "ALL INCIDENTS REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:allIncidentsRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(detectAndResponsePageActions.allIncidentsRequestFailureAC());
    }
  };

export const getDetailedIncidentInfo =
  (selectedContractId: string, incidentId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(detectAndResponsePageActions.detailedIncidentRequestSuccessAC(emptyDetailedIncident));
      dispatch(detectAndResponsePageActions.detailedIncidentRequestAC());
      const { data } = await detectAndResponsePageApi.getDetailedIncident(selectedContractId, incidentId);
      dispatch(detectAndResponsePageActions.detailedIncidentRequestSuccessAC(data.incident));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "DETAILED INCIDENT REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:detailedIncidentRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(detectAndResponsePageActions.detailedIncidentRequestFailureAC());
    }
  };

export const sendIncidentReportViaEmailRequest =
  (contractId: string, incidentId: string, params: object): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(detectAndResponsePageActions.sendEmailRequestAC());
      await detectAndResponsePageApi.sendIncidentReportViaEmail(contractId, incidentId, params);
      dispatch(detectAndResponsePageActions.sendEmailRequestSuccessAC());
      dispatch(notificationAC({ message: i18n.t("notification:emailSendSuccess") }));
    } catch (error) {
      dispatch(detectAndResponsePageActions.sendEmailRequestSuccessAC());
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:emailSendFailure"),
          description: parseApiErrorMessage(error),
        })
      );
    }
  };
