import { RequestStatus } from "types/common.types";
import * as types from "./proactiveSecurityPage.types";

const initState: types.EnhancedProtectionPageStateSlice = {
  exposureScore: {
    currentScore: 0,
    requestStatus: RequestStatus.UNCALLED,
  },
  exposureDistribution: {
    exposureLevelLow: 0,
    exposureLevelMedium: 0,
    exposureLevelHigh: 0,
    requestStatus: RequestStatus.UNCALLED,
  },
  expiringCertificates: {
    expired: 0,
    expiresIn30: 0,
    expiresIn60: 0,
    expiresIn90: 0,
    requestStatus: RequestStatus.UNCALLED,
  },
  deviceScoreHistory: {
    list: [],
    requestStatus: RequestStatus.UNCALLED,
  },
  vulnerableSoftware: {
    list: [],
    requestStatus: RequestStatus.UNCALLED,
  },
  recommendations: {
    list: [],
    requestStatus: RequestStatus.UNCALLED,
  },
};

export default function contractReducer(
  state: types.EnhancedProtectionPageStateSlice = initState,
  action: types.EnhancedProtectionPageAction
): types.EnhancedProtectionPageStateSlice {
  switch (action.type) {
    case types.EXPOSURE_SCORE_REQUEST: {
      return {
        ...state,
        exposureScore: { ...state.exposureScore, requestStatus: RequestStatus.PENDING },
      };
    }
    case types.EXPOSURE_SCORE_REQUEST_SUCCESS: {
      return {
        ...state,
        exposureScore: { ...action.payload, requestStatus: RequestStatus.SUCCESS },
      };
    }
    case types.EXPOSURE_SCORE_REQUEST_FAILURE: {
      return {
        ...state,
        exposureScore: { ...initState.exposureScore, requestStatus: RequestStatus.FAILURE },
      };
    }
    case types.EXPOSURE_DISTRIBUTION_REQUEST: {
      return {
        ...state,
        exposureDistribution: { ...state.exposureDistribution, requestStatus: RequestStatus.PENDING },
      };
    }
    case types.EXPOSURE_DISTRIBUTION_REQUEST_SUCCESS: {
      return {
        ...state,
        exposureDistribution: { ...action.payload, requestStatus: RequestStatus.SUCCESS },
      };
    }
    case types.EXPOSURE_DISTRIBUTION_REQUEST_FAILURE: {
      return {
        ...state,
        exposureDistribution: { ...initState.exposureDistribution, requestStatus: RequestStatus.FAILURE },
      };
    }
    case types.EXPIRING_CERTIFICATES_REQUEST: {
      return {
        ...state,
        expiringCertificates: { ...state.expiringCertificates, requestStatus: RequestStatus.PENDING },
      };
    }
    case types.EXPIRING_CERTIFICATES_REQUEST_SUCCESS: {
      return {
        ...state,
        expiringCertificates: { ...action.payload, requestStatus: RequestStatus.SUCCESS },
      };
    }
    case types.EXPIRING_CERTIFICATES_REQUEST_FAILURE: {
      return {
        ...state,
        expiringCertificates: { ...initState.expiringCertificates, requestStatus: RequestStatus.FAILURE },
      };
    }
    case types.DEVICE_SCORE_HISTORY_REQUEST: {
      return {
        ...state,
        deviceScoreHistory: { ...state.deviceScoreHistory, requestStatus: RequestStatus.PENDING },
      };
    }
    case types.DEVICE_SCORE_HISTORY_REQUEST_SUCCESS: {
      return {
        ...state,
        deviceScoreHistory: { list: action.payload, requestStatus: RequestStatus.SUCCESS },
      };
    }
    case types.DEVICE_SCORE_HISTORY_REQUEST_FAILURE: {
      return {
        ...state,
        deviceScoreHistory: { ...initState.deviceScoreHistory, requestStatus: RequestStatus.FAILURE },
      };
    }
    case types.VULNERABLE_SOFTWARE_REQUEST: {
      return {
        ...state,
        vulnerableSoftware: { ...state.vulnerableSoftware, requestStatus: RequestStatus.PENDING },
      };
    }
    case types.VULNERABLE_SOFTWARE_REQUEST_SUCCESS: {
      return {
        ...state,
        vulnerableSoftware: { list: action.payload, requestStatus: RequestStatus.SUCCESS },
      };
    }
    case types.VULNERABLE_SOFTWARE_REQUEST_FAILURE: {
      return {
        ...state,
        vulnerableSoftware: { ...initState.vulnerableSoftware, requestStatus: RequestStatus.FAILURE },
      };
    }
    case types.RECOMMENDATIONS_REQUEST: {
      return {
        ...state,
        recommendations: { ...state.recommendations, requestStatus: RequestStatus.PENDING },
      };
    }
    case types.RECOMMENDATIONS_REQUEST_SUCCESS: {
      return {
        ...state,
        recommendations: { list: action.payload, requestStatus: RequestStatus.SUCCESS },
      };
    }
    case types.RECOMMENDATIONS_REQUEST_FAILURE: {
      return {
        ...state,
        recommendations: { ...initState.recommendations, requestStatus: RequestStatus.FAILURE },
      };
    }
    default:
      return state;
  }
}
