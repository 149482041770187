import React from "react";
import cn from "classnames";
import ExposureScoreChart from "./charts/ExposureScoreChart";
import ExposureDistributionChart from "./charts/ExposureDistributionChart";
import ExpiringCertificatesChart from "./charts/ExpiringCertificatesChart";
import DeviceScoreHistoryChart from "./charts/DeviceScoreHistoryChart";

import styles from "./styles.module.css";

const ProactiveSecurityPageCharts = () => {
  return (
    <div className={styles.chartsContainer}>
      <div className={cn(styles.chartsItem, styles.securitySmallCard)}>
        <ExposureScoreChart />
      </div>
      <div className={cn(styles.chartsItem, styles.securitySmallCard)}>
        <ExposureDistributionChart />
      </div>
      <div className={cn(styles.chartsItem, styles.securitySmallCard)}>
        <ExpiringCertificatesChart />
      </div>
      <div className={styles.chartsItem}>
        <DeviceScoreHistoryChart />
      </div>
    </div>
  );
};

export default ProactiveSecurityPageCharts;
