import * as types from "./proactiveSecurityPage.types";

export const exposureScoreRequestAC: types.ExposureScoreRequestAC = () => ({
  type: types.EXPOSURE_SCORE_REQUEST,
});

export const exposureScoreRequestSuccessAC: types.ExposureScoreRequestSuccessAC = (payload) => ({
  type: types.EXPOSURE_SCORE_REQUEST_SUCCESS,
  payload,
});

export const exposureScoreRequestFailureAC: types.ExposureScoreRequestFailureAC = () => ({
  type: types.EXPOSURE_SCORE_REQUEST_FAILURE,
});

export const exposureDistributionRequestAC: types.ExposureDistributionRequestAC = () => ({
  type: types.EXPOSURE_DISTRIBUTION_REQUEST,
});

export const exposureDistributionRequestSuccessAC: types.ExposureDistributionRequestSuccessAC = (payload) => ({
  type: types.EXPOSURE_DISTRIBUTION_REQUEST_SUCCESS,
  payload,
});

export const exposureDistributionRequestFailureAC: types.ExposureDistributionRequestFailureAC = () => ({
  type: types.EXPOSURE_DISTRIBUTION_REQUEST_FAILURE,
});

export const expiringCertificatesRequestAC: types.ExpiringCertificatesRequestAC = () => ({
  type: types.EXPIRING_CERTIFICATES_REQUEST,
});

export const expiringCertificatesRequestSuccessAC: types.ExpiringCertificatesRequestSuccessAC = (payload) => ({
  type: types.EXPIRING_CERTIFICATES_REQUEST_SUCCESS,
  payload,
});

export const expiringCertificatesRequestFailureAC: types.ExpiringCertificatesRequestFailureAC = () => ({
  type: types.EXPIRING_CERTIFICATES_REQUEST_FAILURE,
});

export const deviceScoreHistoryRequestAC: types.DeviceScoreHistoryRequestAC = () => ({
  type: types.DEVICE_SCORE_HISTORY_REQUEST,
});

export const deviceScoreHistoryRequestSuccessAC: types.DeviceScoreHistoryRequestSuccessAC = (payload) => ({
  type: types.DEVICE_SCORE_HISTORY_REQUEST_SUCCESS,
  payload,
});

export const deviceScoreHistoryRequestFailureAC: types.DeviceScoreHistoryRequestFailureAC = () => ({
  type: types.DEVICE_SCORE_HISTORY_REQUEST_FAILURE,
});

export const vulnerableSoftwareRequestAC: types.VulnerableSoftwareRequestAC = () => ({
  type: types.VULNERABLE_SOFTWARE_REQUEST,
});

export const vulnerableSoftwareRequestSuccessAC: types.VulnerableSoftwareRequestSuccessAC = (payload) => ({
  type: types.VULNERABLE_SOFTWARE_REQUEST_SUCCESS,
  payload,
});

export const vulnerableSoftwareRequestFailureAC: types.VulnerableSoftwareRequestFailureAC = () => ({
  type: types.VULNERABLE_SOFTWARE_REQUEST_FAILURE,
});

export const recommendationsRequestAC: types.RecommendationsRequestAC = () => ({
  type: types.RECOMMENDATIONS_REQUEST,
});

export const recommendationsRequestSuccessAC: types.RecommendationsRequestSuccessAC = (payload) => ({
  type: types.RECOMMENDATIONS_REQUEST_SUCCESS,
  payload,
});

export const recommendationsRequestFailureAC: types.RecommendationsRequestFailureAC = () => ({
  type: types.RECOMMENDATIONS_REQUEST_FAILURE,
});
