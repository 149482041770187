import { useDispatch, useSelector } from "react-redux";
import { contractActions, contractSelectors } from "../../store/contracts";
import { DropdownProps } from "@skyportal/ui-kit";
import { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  getSDContractList,
  getSEContractList,
  getSelectedContract,
  getSSContractList,
} from "store/contracts/contracts.selectors";
import { ContractModel, ContractsStateSlice } from "store/contracts/contracts.types";

interface UseContractDropdown {
  options: DropdownProps["options"];
  handleChange: DropdownProps["onChange"];
  value: string;
}

export const contractSelectorsByPath: any = {
  "/security/security-ready": getSSContractList,
  "/security/proactive-security": getSEContractList,
  "/security/detection-and-response": getSDContractList,
};

const useContractDropdown = (): UseContractDropdown => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const currentPath =
    Object.keys(contractSelectorsByPath).find((prop) => pathname.includes(prop)) || "/security/security-ready";
  const currentContracts: ContractsStateSlice["SS"] | ContractsStateSlice["SE"] | ContractsStateSlice["SD"] =
    useSelector(contractSelectorsByPath[currentPath]);
  const selectedContract = useSelector(getSelectedContract);

  useEffect(() => {
    // on path change set contract of other type, but of the same parent or the first one if parent doesn't have contracts of this type
    const defaultContract =
      currentContracts.find((contract) => contract.parentId === selectedContract?.parentId) || currentContracts[0];
    dispatch(contractActions.selectContractAC(defaultContract));
  }, [pathname]);

  return {
    value: (selectedContract?.name as string) ?? "",
    handleChange: useCallback(
      (value) => {
        const selectedContract = currentContracts.find((contract) => contract.name === value);
        dispatch(contractActions.selectContractAC(selectedContract as ContractModel));
      },
      [currentContracts, dispatch]
    ),
    options: useSelector(contractSelectors.getContractDropdownOptions(currentPath)),
  };
};

export default useContractDropdown;
