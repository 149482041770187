import { AppThunk } from "store";
import * as proactiveSecurityPageApi from "api/proactiveSecurityPage.api";
import * as proactiveSecurityPageActions from "./proactiveSecurityPage.actions";
import { notificationErrorAC } from "store/notification/notification.actions";
import i18n from "locales";
import { parseApiErrorMessage } from "utils/helpers";

export const getExposureScoreInfo =
  (selectedContractId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(proactiveSecurityPageActions.exposureScoreRequestAC());
      const { data } = await proactiveSecurityPageApi.getExposureScore(selectedContractId);
      dispatch(proactiveSecurityPageActions.exposureScoreRequestSuccessAC(data.exposureScore));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "EXPOSURE SCORE REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:exposureScoreRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(proactiveSecurityPageActions.exposureScoreRequestFailureAC());
    }
  };

export const getExposureDistributionInfo =
  (selectedContractId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(proactiveSecurityPageActions.exposureDistributionRequestAC());
      const { data } = await proactiveSecurityPageApi.getExposureDistribution(selectedContractId);
      dispatch(proactiveSecurityPageActions.exposureDistributionRequestSuccessAC(data.exposureDistribution));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "EXPOSURE DISTRIBUTION REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:exposureDistributionRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(proactiveSecurityPageActions.exposureDistributionRequestFailureAC());
    }
  };

export const getExpiringCertificatesInfo =
  (selectedContractId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(proactiveSecurityPageActions.expiringCertificatesRequestAC());
      const { data } = await proactiveSecurityPageApi.getExpiringCertificates(selectedContractId);
      dispatch(proactiveSecurityPageActions.expiringCertificatesRequestSuccessAC(data.expiringCertificates));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "EXPIRING CERTIFICATES REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:expiringCertificatesRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(proactiveSecurityPageActions.expiringCertificatesRequestFailureAC());
    }
  };

export const getDeviceScoreHistoryInfo =
  (selectedContractId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(proactiveSecurityPageActions.deviceScoreHistoryRequestAC());
      const { data } = await proactiveSecurityPageApi.getDeviceScoreHistory(selectedContractId);
      dispatch(proactiveSecurityPageActions.deviceScoreHistoryRequestSuccessAC(data.deviceScoreHistory));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "DEVICE SCORE HISTORY REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:deviceScoreHistoryRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(proactiveSecurityPageActions.deviceScoreHistoryRequestFailureAC());
    }
  };

export const getVulnerableSoftwareInfo =
  (selectedContractId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(proactiveSecurityPageActions.vulnerableSoftwareRequestAC());
      const { data } = await proactiveSecurityPageApi.getVulnerableSoftware(selectedContractId);
      dispatch(proactiveSecurityPageActions.vulnerableSoftwareRequestSuccessAC(data.vulnerableSoftware));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "VULNERABLE SOFTWARE REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:vulnerableSoftwareRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(proactiveSecurityPageActions.vulnerableSoftwareRequestFailureAC());
    }
  };

export const getRecommendationsInfo =
  (selectedContractId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(proactiveSecurityPageActions.recommendationsRequestAC());
      const { data } = await proactiveSecurityPageApi.getRecommendations(selectedContractId);
      dispatch(proactiveSecurityPageActions.recommendationsRequestSuccessAC(data.recommendations));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "RECOMMENDATIONS REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:recommendationsRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(proactiveSecurityPageActions.recommendationsRequestFailureAC());
    }
  };
