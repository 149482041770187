import { securityApiClient } from "./clients";
import {
  DetailedIncidentResponse,
  IncidentSeverityResponse,
  IncidentStatusResponse,
  IncidentsResponse,
  UnresolvedIncidentsResponse,
} from "types/detectAndResponseApi.types";

export const getUnresolvedIncidents = (contractId: string) =>
  securityApiClient.request<UnresolvedIncidentsResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/security/unresolved-incidents`,
  });

export const getIncidentSeverity = (contractId: string) =>
  securityApiClient.request<IncidentSeverityResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/security/incident-severity`,
  });

export const getIncidentStatus = (contractId: string) =>
  securityApiClient.request<IncidentStatusResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/security/incident-status`,
  });

export const getIncidents = (contractId: string, quantity?: number) =>
  securityApiClient.request<IncidentsResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/security/incidents${quantity ? `?top=${quantity}` : ""}`,
  });

export const getDetailedIncident = (contractId: string, incidentId: string) =>
  securityApiClient.request<DetailedIncidentResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/security/incidents/${incidentId}`,
  });

export const sendIncidentReportViaEmail = (contractId: string, incidentId: string, params: object) =>
  securityApiClient.request({
    url: `/v1/contracts/${contractId}/security/incidents/${incidentId}`,
    method: "POST",
    params,
    timeout: 30000,
  });
