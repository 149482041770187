import { RequestStatus } from "types/common.types";
import * as types from "./detectAndResponsePage.types";

export const emptyAllIncidentsFilters = { status: "", severity: "", id: "", subject: "" };
export const emptyDetailedIncident = {
  status: "",
  severity: "",
  id: "",
  subject: "",
  created: "",
  updated: "",
  closed: "",
  reopen: "",
  revision: 0,
  affectedConfigurationItems: [],
  summary: "",
  technicalDetails: "",
  recommendedAction: "",
  log: [],
  logCurrentPage: 1,
  logPerPage: 10,
  requestStatus: RequestStatus.UNCALLED,
};

const initState: types.DetectAndResponsePageStateSlice = {
  unresolvedIncidents: {
    count: 0,
    requestStatus: RequestStatus.UNCALLED,
  },
  incidentSeverity: {
    list: [],
    requestStatus: RequestStatus.UNCALLED,
  },
  incidentStatus: {
    list: [],
    requestStatus: RequestStatus.UNCALLED,
  },
  top5Incidents: {
    list: [],
    requestStatus: RequestStatus.UNCALLED,
  },
  allIncidents: {
    list: [],
    filters: emptyAllIncidentsFilters,
    sortField: "created",
    sortOrder: "desc",
    currentPage: 1,
    perPage: 10,
    requestStatus: RequestStatus.UNCALLED,
  },
  detailedIncident: emptyDetailedIncident,
  sendEmailRequestStatus: RequestStatus.UNCALLED,
};

export default function contractReducer(
  state: types.DetectAndResponsePageStateSlice = initState,
  action: types.EnhancedProtectionPageAction
): types.DetectAndResponsePageStateSlice {
  switch (action.type) {
    case types.UNRESOLVED_INCIDENTS_REQUEST: {
      return {
        ...state,
        unresolvedIncidents: { ...state.unresolvedIncidents, requestStatus: RequestStatus.PENDING },
      };
    }
    case types.UNRESOLVED_INCIDENTS_REQUEST_SUCCESS: {
      return {
        ...state,
        unresolvedIncidents: { ...action.payload, requestStatus: RequestStatus.SUCCESS },
      };
    }
    case types.UNRESOLVED_INCIDENTS_REQUEST_FAILURE: {
      return {
        ...state,
        unresolvedIncidents: { ...initState.unresolvedIncidents, requestStatus: RequestStatus.FAILURE },
      };
    }
    case types.INCIDENT_SEVERITY_REQUEST: {
      return {
        ...state,
        incidentSeverity: { ...state.incidentSeverity, requestStatus: RequestStatus.PENDING },
      };
    }
    case types.INCIDENT_SEVERITY_REQUEST_SUCCESS: {
      return {
        ...state,
        incidentSeverity: { list: action.payload, requestStatus: RequestStatus.SUCCESS },
      };
    }
    case types.INCIDENT_SEVERITY_REQUEST_FAILURE: {
      return {
        ...state,
        incidentSeverity: { ...initState.incidentSeverity, requestStatus: RequestStatus.FAILURE },
      };
    }
    case types.INCIDENT_STATUS_REQUEST: {
      return {
        ...state,
        incidentStatus: { ...state.incidentStatus, requestStatus: RequestStatus.PENDING },
      };
    }
    case types.INCIDENT_STATUS_REQUEST_SUCCESS: {
      return {
        ...state,
        incidentStatus: { list: action.payload, requestStatus: RequestStatus.SUCCESS },
      };
    }
    case types.INCIDENT_STATUS_REQUEST_FAILURE: {
      return {
        ...state,
        incidentStatus: { ...initState.incidentStatus, requestStatus: RequestStatus.FAILURE },
      };
    }
    case types.TOP_5_INCIDENTS_REQUEST: {
      return {
        ...state,
        top5Incidents: { ...state.top5Incidents, requestStatus: RequestStatus.PENDING },
      };
    }
    case types.TOP_5_INCIDENTS_REQUEST_SUCCESS: {
      return {
        ...state,
        top5Incidents: { list: action.payload, requestStatus: RequestStatus.SUCCESS },
      };
    }
    case types.TOP_5_INCIDENTS_REQUEST_FAILURE: {
      return {
        ...state,
        top5Incidents: { ...initState.top5Incidents, requestStatus: RequestStatus.FAILURE },
      };
    }
    case types.ALL_INCIDENTS_REQUEST: {
      return {
        ...state,
        allIncidents: { ...state.allIncidents, requestStatus: RequestStatus.PENDING },
      };
    }
    case types.ALL_INCIDENTS_REQUEST_SUCCESS: {
      return {
        ...state,
        allIncidents: { ...state.allIncidents, list: action.payload, requestStatus: RequestStatus.SUCCESS },
      };
    }
    case types.ALL_INCIDENTS_REQUEST_FAILURE: {
      return {
        ...state,
        allIncidents: { ...initState.allIncidents, requestStatus: RequestStatus.FAILURE },
      };
    }

    case types.SET_ALL_INCIDENTS_FILTERS: {
      return {
        ...state,
        allIncidents: {
          ...state.allIncidents,
          filters: action.payload,
        },
      };
    }
    case types.SET_ALL_INCIDENTS_SORT_FIELD: {
      return {
        ...state,
        allIncidents: {
          ...state.allIncidents,
          sortField:
            state.allIncidents.sortField === action.payload && state.allIncidents.sortOrder === "asc"
              ? ""
              : action.payload,
          sortOrder:
            state.allIncidents.sortField === action.payload && state.allIncidents.sortOrder === "desc" ? "asc" : "desc",
          currentPage: 1,
        },
      };
    }
    case types.SET_ALL_INCIDENTS_PER_PAGE: {
      return {
        ...state,
        allIncidents: {
          ...state.allIncidents,
          perPage: action.payload,
          currentPage: 1,
        },
      };
    }
    case types.SET_ALL_INCIDENTS_CURRENT_PAGE: {
      return {
        ...state,
        allIncidents: {
          ...state.allIncidents,
          currentPage: action.payload,
        },
      };
    }
    case types.DETAILED_INCIDENT_REQUEST: {
      return {
        ...state,
        detailedIncident: { ...state.detailedIncident, requestStatus: RequestStatus.PENDING },
      };
    }
    case types.DETAILED_INCIDENT_REQUEST_SUCCESS: {
      return {
        ...state,
        detailedIncident: { ...state.detailedIncident, ...action.payload, requestStatus: RequestStatus.SUCCESS },
      };
    }
    case types.DETAILED_INCIDENT_REQUEST_FAILURE: {
      return {
        ...state,
        detailedIncident: { ...initState.detailedIncident, requestStatus: RequestStatus.FAILURE },
      };
    }
    case types.SET_DETAILED_INCIDENT_LOG_PER_PAGE: {
      return {
        ...state,
        detailedIncident: {
          ...state.detailedIncident,
          logPerPage: action.payload,
          logCurrentPage: 1,
        },
      };
    }
    case types.SET_DETAILED_INCIDENT_LOG_CURRENT_PAGE: {
      return {
        ...state,
        detailedIncident: {
          ...state.detailedIncident,
          logCurrentPage: action.payload,
        },
      };
    }
    case types.SEND_EMAIL_REQUEST:
      return {
        ...state,
        sendEmailRequestStatus: RequestStatus.PENDING,
      };

    case types.SEND_EMAIL_REQUEST_SUCCESS:
      return {
        ...state,
        sendEmailRequestStatus: RequestStatus.SUCCESS,
      };

    case types.SEND_EMAIL_REQUEST_FAILED:
      return {
        ...state,
        sendEmailRequestStatus: RequestStatus.FAILURE,
      };
    default:
      return state;
  }
}
