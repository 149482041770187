import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import SecurityPage from "pages/SecurityPage";
import ProactiveSecurityPage from "pages/ProactiveSecurityPage";
import DetectAndResponsePage from "pages/DetectAndResponsePage";
import { getSDContractList, getSEContractList, getSSContractList } from "store/contracts/contracts.selectors";

export const AppRouter: React.FC = () => {
  const SSContracts = useSelector(getSSContractList);
  const SEContracts = useSelector(getSEContractList);
  const SDContracts = useSelector(getSDContractList);

  const getDefaultUrl = () => {
    switch (true) {
      case !!SEContracts.length:
        return "/security/proactive-security";
      case !!SSContracts.length && !SEContracts.length:
        return "/security/security-ready";
      case !!SDContracts.length:
        return "/security/detection-and-response";

      default:
        return "/security";
    }
  };

  return (
    <Switch>
      {!!SSContracts.length && !SEContracts.length && (
        <Route path="/security/security-ready" exact component={SecurityPage} />
      )}
      {!!SEContracts.length && <Route path="/security/proactive-security" component={ProactiveSecurityPage} />}
      {!!SDContracts.length && <Route path="/security/detection-and-response" component={DetectAndResponsePage} />}
      <Route path="*">
        <Redirect to={getDefaultUrl()} />
      </Route>
    </Switch>
  );
};

export default AppRouter;
