import { IncidentStatusModel } from "types/detectAndResponseApi.types";
import { pieChartLegendLabelFormatter } from "components/ProactiveSecurityPageCharts/charts/ExposureDistributionChart/utils";

export const incidentStatusColorChartMap: Record<string, string> = {
  Resolved: "#4ef09d",
  "Accepted risk": "#44d9fc",
  Dispensation: "#e59a7f",
  Mitigated: "#ffd0d0",
  Unresolved: "#ff5a5a",
};

export const composeChartOptions = (incidentStatus: IncidentStatusModel[]) => {
  return {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      borderRadius: 20,
      plotShadow: false,
      type: "pie",
      height: 350,
      spacingBottom: 8,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },
    tooltip: {
      pointFormat: "Quantity: <b>{point.y}</b><br> Distribution: <b>{point.percentage:.1f}%</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },
    series: [
      {
        data: incidentStatus.map((category) => ({
          name: category.status,
          y: category.count,
          color: incidentStatusColorChartMap[category.status],
        })),
      },
    ],
    legend: {
      itemDistance: 20,
      alignColumns: true,
      useHTML: true,
      labelFormatter: pieChartLegendLabelFormatter,
      navigation: {
        arrowSize: 10,
        activeColor: "#000",
        inactiveColor: "#ccc",
        style: {
          fontWeight: "bold",
        },
      },
    },
  };
};
