import { IntuneEnrolledDevicesModel } from "types/startPageApi.types";

const colors = ["#c48df7", "#ffd0d0", "#e59a7f", "#44d9fc", "#f3e8fd", "#c5f5fa", "#faebe5"];

export const composeChartOptions = (intuneEnrolledDevices: IntuneEnrolledDevicesModel[]) => {
  const totalDevicesValue = intuneEnrolledDevices
    .map((device) => device.count)
    .reduce((partialSum, a) => partialSum + a, 0);

  return {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      borderRadius: 20,
      plotShadow: false,
      type: "bar",
      height: 350,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },
    tooltip: {
      shared: true,
      formatter: function () {
        const chartData = this as any;
        return chartData.points.reverse().map((point: any, index: number) =>
          index === 0
            ? `<span style="font-family: Dustin Sans;">
                <span style="font-size: 10px;">${point.x}</span><br>
                <span style="color:${point.color}">${point.series.name}:</span>
                <span><b>${point.y}</b></span>
              </span><br>`
            : null
        );
      },
    },
    accessibility: {
      point: {
        valueSuffix: "pcs",
      },
    },
    plotOptions: {
      bar: {
        animation: {
          duration: 1000,
        },
      },
      series: {
        stacking: "normal",
        groupPadding: 0,
      },
    },
    xAxis: {
      categories: [...intuneEnrolledDevices.map((score) => score.operatingSystem), "Total"],
      labels: {
        formatter: function () {
          const chartData = this as any;
          const devicesCount = chartData.chart.series[1].processedYData[chartData.pos];
          return `${chartData.value}: ${devicesCount}`;
        },
        align: "left",
        x: 4,
        y: 4,
        style: {
          fontSize: "12px",
          color: "#333",
          whiteSpace: "nowrap",
        },
      },
    },
    yAxis: {
      title: null,
      max: totalDevicesValue,
      labels: {
        step: 1,
      },
    },
    series: [
      {
        data: [
          ...intuneEnrolledDevices.map((score) => ({ y: totalDevicesValue - score.count, color: "#f0f0f0" })),
          { y: 0, color: "f0f0f0" },
        ],
      },
      {
        name: "Devices",
        data: [
          ...intuneEnrolledDevices.map((score, index) => ({
            y: score.count,
            color: index <= colors.length - 1 ? colors[index] : colors[index - colors.length],
          })),
          { y: totalDevicesValue, color: "#b4b4b4" },
        ],
      },
    ],
    legend: {
      enabled: false,
    },
  };
};
