import { IncidentModel, LogItem } from "types/detectAndResponseApi.types";
import { RootState } from "../index";
import { AllIncidentsFiltersType, DetectAndResponsePageStateSlice } from "./detectAndResponsePage.types";
import { getPaginationSlice, makeUniversalSort } from "utils/table";

export const getSlice = (state: RootState) => state.detectAndResponsePage;

export const getUnresolvedIncidents = (state: RootState) => getSlice(state).unresolvedIncidents;
export const getIncidentSeverity = (state: RootState) => getSlice(state).incidentSeverity;
export const getIncidentStatus = (state: RootState) => getSlice(state).incidentStatus;
export const getTop5Incidents = (state: RootState) => getSlice(state).top5Incidents;
export const getAllIncidents = (state: RootState) => getSlice(state).allIncidents;
export const getDetailedIncident = (state: RootState) => getSlice(state).detailedIncident;
export const getSendEmailRequestStatus = (state: RootState) => getSlice(state).sendEmailRequestStatus;

const makeStatusSortNormalizer = (item: IncidentModel) => item.status;
const makeSeveritySortNormalizer = (item: IncidentModel) => item.severity;
const makeIdSortNormalizer = (item: IncidentModel) => item.id;
const makeSubjectSortNormalizer = (item: IncidentModel) => item.subject;
const makeCreatedSortNormalizer = (item: IncidentModel) => item.created;
const makeUpdatedSortNormalizer = (item: IncidentModel) => item.updated;

const allIncidentsComposer = ({
  list,
  sortField,
  sortOrder,
  currentPage,
  perPage,
  filters,
}: DetectAndResponsePageStateSlice["allIncidents"]) => {
  let _list: IncidentModel[] = [...list];

  const sortHandler: any = {
    status: makeUniversalSort(makeStatusSortNormalizer),
    severity: makeUniversalSort(makeSeveritySortNormalizer),
    id: makeUniversalSort(makeIdSortNormalizer),
    subject: makeUniversalSort(makeSubjectSortNormalizer),
    created: makeUniversalSort(makeCreatedSortNormalizer),
    updated: makeUniversalSort(makeUpdatedSortNormalizer),
  };

  if (sortField && sortHandler[sortField]) {
    _list.sort(sortHandler[sortField](sortOrder));
  }

  Object.keys(filters).forEach((filterName) => {
    if (filters[filterName as keyof AllIncidentsFiltersType]) {
      _list = _list.filter((item) =>
        item[filterName as keyof AllIncidentsFiltersType]
          .toString()
          .toLowerCase()
          .startsWith(filters[filterName as keyof AllIncidentsFiltersType].toLowerCase())
      );
    }
  });

  const total = _list.length;

  return {
    total,
    pageCount: Math.ceil(total / perPage),
    list: getPaginationSlice(_list, currentPage, perPage),
  };
};

export const getAllIncidentsTable = (state: RootState) => {
  const allInsidentsSlice = getAllIncidents(state);
  return allIncidentsComposer(allInsidentsSlice);
};

const detailedIncidentLogComposer = ({
  log,
  logPerPage,
  logCurrentPage,
}: DetectAndResponsePageStateSlice["detailedIncident"]) => {
  let _list: LogItem[] = [...log];

  const total = _list.length;

  return {
    total,
    pageCount: Math.ceil(total / logPerPage),
    list: getPaginationSlice(_list, logCurrentPage, logPerPage),
  };
};

export const getDetailedIncidentLog = (state: RootState) => {
  const detailedIncidentSlice = getDetailedIncident(state);
  return detailedIncidentLogComposer(detailedIncidentSlice);
};
