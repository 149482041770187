import { RequestStatus } from "types/common.types";
import { DetailedIncidentModel, IncidentModel } from "types/detectAndResponseApi.types";
import { IncidentStatusModel } from "types/detectAndResponseApi.types";
import { IncidentSeverityModel } from "types/detectAndResponseApi.types";
import { UnresolvedIncidentsModel } from "types/detectAndResponseApi.types";

export type SortFieldType = keyof IncidentModel | "";
export type SortOrderType = "asc" | "desc";

export type AllIncidentsFiltersType = Omit<Record<keyof IncidentModel, string>, "created" | "updated">;

export interface WithRequestStatus {
  requestStatus: RequestStatus;
}

export interface DetectAndResponsePageStateSlice {
  readonly unresolvedIncidents: UnresolvedIncidentsModel & WithRequestStatus;
  readonly incidentSeverity: { list: IncidentSeverityModel[] } & WithRequestStatus;
  readonly incidentStatus: { list: IncidentStatusModel[] } & WithRequestStatus;
  readonly top5Incidents: { list: IncidentModel[] } & WithRequestStatus;
  readonly allIncidents: {
    readonly list: IncidentModel[];
    readonly filters: AllIncidentsFiltersType;
    readonly sortField: SortFieldType;
    readonly sortOrder: SortOrderType;
    readonly currentPage: number;
    readonly perPage: number;
  } & WithRequestStatus;
  readonly detailedIncident: { logPerPage: number; logCurrentPage: number } & DetailedIncidentModel & WithRequestStatus;
  readonly sendEmailRequestStatus: RequestStatus;
}

export const UNRESOLVED_INCIDENTS_REQUEST = "UNRESOLVED_INCIDENTS_REQUEST";
export type UnresolvedIncidentsRequestAction = {
  type: typeof UNRESOLVED_INCIDENTS_REQUEST;
};
export type UnresolvedIncidentsRequestAC = () => UnresolvedIncidentsRequestAction;

export const UNRESOLVED_INCIDENTS_REQUEST_SUCCESS = "UNRESOLVED_INCIDENTS_REQUEST_SUCCESS";
export type UnresolvedIncidentsRequestSuccessAction = {
  type: typeof UNRESOLVED_INCIDENTS_REQUEST_SUCCESS;
  payload: UnresolvedIncidentsModel;
};
export type UnresolvedIncidentsRequestSuccessAC = (
  payload: UnresolvedIncidentsModel
) => UnresolvedIncidentsRequestSuccessAction;

export const UNRESOLVED_INCIDENTS_REQUEST_FAILURE = "UNRESOLVED_INCIDENTS_REQUEST_FAILURE";
export type UnresolvedIncidentsRequestFailureAction = {
  type: typeof UNRESOLVED_INCIDENTS_REQUEST_FAILURE;
};
export type UnresolvedIncidentsRequestFailureAC = () => UnresolvedIncidentsRequestFailureAction;

export const INCIDENT_SEVERITY_REQUEST = "INCIDENT_SEVERITY_REQUEST";
export type IncidentSeverityRequestAction = {
  type: typeof INCIDENT_SEVERITY_REQUEST;
};
export type IncidentSeverityRequestAC = () => IncidentSeverityRequestAction;

export const INCIDENT_SEVERITY_REQUEST_SUCCESS = "INCIDENT_SEVERITY_REQUEST_SUCCESS";
export type IncidentSeverityRequestSuccessAction = {
  type: typeof INCIDENT_SEVERITY_REQUEST_SUCCESS;
  payload: IncidentSeverityModel[];
};
export type IncidentSeverityRequestSuccessAC = (
  payload: IncidentSeverityModel[]
) => IncidentSeverityRequestSuccessAction;

export const INCIDENT_SEVERITY_REQUEST_FAILURE = "INCIDENT_SEVERITY_REQUEST_FAILURE";
export type IncidentSeverityRequestFailureAction = {
  type: typeof INCIDENT_SEVERITY_REQUEST_FAILURE;
};
export type IncidentSeverityRequestFailureAC = () => IncidentSeverityRequestFailureAction;

export const INCIDENT_STATUS_REQUEST = "INCIDENT_STATUS_REQUEST";
export type IncidentStatusRequestAction = {
  type: typeof INCIDENT_STATUS_REQUEST;
};
export type IncidentStatusRequestAC = () => IncidentStatusRequestAction;

export const INCIDENT_STATUS_REQUEST_SUCCESS = "INCIDENT_STATUS_REQUEST_SUCCESS";
export type IncidentStatusRequestSuccessAction = {
  type: typeof INCIDENT_STATUS_REQUEST_SUCCESS;
  payload: IncidentStatusModel[];
};
export type IncidentStatusRequestSuccessAC = (payload: IncidentStatusModel[]) => IncidentStatusRequestSuccessAction;

export const INCIDENT_STATUS_REQUEST_FAILURE = "INCIDENT_STATUS_REQUEST_FAILURE";
export type IncidentStatusRequestFailureAction = {
  type: typeof INCIDENT_STATUS_REQUEST_FAILURE;
};
export type IncidentStatusRequestFailureAC = () => IncidentStatusRequestFailureAction;

export const TOP_5_INCIDENTS_REQUEST = "TOP_5_INCIDENTS_REQUEST";
export type Top5IncidentsRequestAction = {
  type: typeof TOP_5_INCIDENTS_REQUEST;
};
export type Top5IncidentsRequestAC = () => Top5IncidentsRequestAction;

export const TOP_5_INCIDENTS_REQUEST_SUCCESS = "TOP_5_INCIDENTS_REQUEST_SUCCESS";
export type Top5IncidentsRequestSuccessAction = {
  type: typeof TOP_5_INCIDENTS_REQUEST_SUCCESS;
  payload: IncidentModel[];
};
export type Top5IncidentsRequestSuccessAC = (payload: IncidentModel[]) => Top5IncidentsRequestSuccessAction;

export const TOP_5_INCIDENTS_REQUEST_FAILURE = "TOP_5_INCIDENTS_REQUEST_FAILURE";
export type Top5IncidentsRequestFailureAction = {
  type: typeof TOP_5_INCIDENTS_REQUEST_FAILURE;
};
export type Top5IncidentsRequestFailureAC = () => Top5IncidentsRequestFailureAction;

export const ALL_INCIDENTS_REQUEST = "ALL_INCIDENTS_REQUEST";
export type AllIncidentsRequestAction = {
  type: typeof ALL_INCIDENTS_REQUEST;
};
export type AllIncidentsRequestAC = () => AllIncidentsRequestAction;

export const ALL_INCIDENTS_REQUEST_SUCCESS = "ALL_INCIDENTS_REQUEST_SUCCESS";
export type AllIncidentsRequestSuccessAction = {
  type: typeof ALL_INCIDENTS_REQUEST_SUCCESS;
  payload: IncidentModel[];
};
export type AllIncidentsRequestSuccessAC = (payload: IncidentModel[]) => AllIncidentsRequestSuccessAction;

export const ALL_INCIDENTS_REQUEST_FAILURE = "ALL_INCIDENTS_REQUEST_FAILURE";
export type AllIncidentsRequestFailureAction = {
  type: typeof ALL_INCIDENTS_REQUEST_FAILURE;
};
export type AllIncidentsRequestFailureAC = () => AllIncidentsRequestFailureAction;

export const SET_ALL_INCIDENTS_FILTERS = "SET_ALL_INCIDENTS_FILTERS";
export type SetAllIncidentsFiltersAction = { type: typeof SET_ALL_INCIDENTS_FILTERS; payload: AllIncidentsFiltersType };
export type SetAllIncidentsFiltersAC = (payload: AllIncidentsFiltersType) => SetAllIncidentsFiltersAction;

export const SET_ALL_INCIDENTS_SORT_FIELD = "SET_ALL_INCIDENTS_SORT_FIELD";
export type SetAllIncidentsSortFieldAction = {
  type: typeof SET_ALL_INCIDENTS_SORT_FIELD;
  payload: SortFieldType;
};
export type SetAllIncidentsSortFieldAC = (payload: SortFieldType) => SetAllIncidentsSortFieldAction;

export const SET_ALL_INCIDENTS_PER_PAGE = "SET_ALL_INCIDENTS_PER_PAGE";
export type SetAllIncidentsPerPageAction = {
  type: typeof SET_ALL_INCIDENTS_PER_PAGE;
  payload: number;
};
export type SetAllIncidentsPerPageAC = (payload: number) => SetAllIncidentsPerPageAction;

export const SET_ALL_INCIDENTS_CURRENT_PAGE = "SET_ALL_INCIDENTS_CURRENT_PAGE";
export type SetAllIncidentsCurrentPageAction = {
  type: typeof SET_ALL_INCIDENTS_CURRENT_PAGE;
  payload: number;
};
export type SetAllIncidentsCurrentPageAC = (payload: number) => SetAllIncidentsCurrentPageAction;

export const DETAILED_INCIDENT_REQUEST = "DETAILED_INCIDENT_REQUEST";
export type DetailedIncidentRequestAction = {
  type: typeof DETAILED_INCIDENT_REQUEST;
};
export type DetailedIncidentRequestAC = () => DetailedIncidentRequestAction;

export const DETAILED_INCIDENT_REQUEST_SUCCESS = "DETAILED_INCIDENT_REQUEST_SUCCESS";
export type DetailedIncidentRequestSuccessAction = {
  type: typeof DETAILED_INCIDENT_REQUEST_SUCCESS;
  payload: DetailedIncidentModel;
};
export type DetailedIncidentRequestSuccessAC = (payload: DetailedIncidentModel) => DetailedIncidentRequestSuccessAction;

export const DETAILED_INCIDENT_REQUEST_FAILURE = "DETAILED_INCIDENT_REQUEST_FAILURE";
export type DetailedIncidentRequestFailureAction = {
  type: typeof DETAILED_INCIDENT_REQUEST_FAILURE;
};
export type DetailedIncidentRequestFailureAC = () => DetailedIncidentRequestFailureAction;

export const SET_DETAILED_INCIDENT_LOG_PER_PAGE = "SET_DETAILED_INCIDENT_LOG_PER_PAGE";
export type SetDetailedIncidentLogPerPageAction = {
  type: typeof SET_DETAILED_INCIDENT_LOG_PER_PAGE;
  payload: number;
};
export type SetDetailedIncidentLogPerPageAC = (payload: number) => SetDetailedIncidentLogPerPageAction;

export const SET_DETAILED_INCIDENT_LOG_CURRENT_PAGE = "SET_DETAILED_INCIDENT_LOG_CURRENT_PAGE";
export type SetDetailedIncidentLogCurrentPageAction = {
  type: typeof SET_DETAILED_INCIDENT_LOG_CURRENT_PAGE;
  payload: number;
};
export type SetDetailedIncidentLogCurrentPageAC = (payload: number) => SetDetailedIncidentLogCurrentPageAction;

export const SEND_EMAIL_REQUEST = "SEND_EMAIL_REQUEST";
export type SendEmailRequestAction = {
  type: typeof SEND_EMAIL_REQUEST;
};
export type SendEmailRequestAC = () => SendEmailRequestAction;

export const SEND_EMAIL_REQUEST_SUCCESS = "SEND_EMAIL_REQUEST_SUCCESS";
export type SendEmailRequestSuccessAction = {
  type: typeof SEND_EMAIL_REQUEST_SUCCESS;
};
export type SendEmailRequestSuccessAC = () => SendEmailRequestSuccessAction;

export const SEND_EMAIL_REQUEST_FAILED = "SEND_EMAIL_REQUEST_FAILED";
export type SendEmailRequestFailedAction = {
  type: typeof SEND_EMAIL_REQUEST_FAILED;
};
export type SendEmailRequestFailedAC = () => SendEmailRequestFailedAction;

export type EnhancedProtectionPageAction =
  | UnresolvedIncidentsRequestAction
  | UnresolvedIncidentsRequestSuccessAction
  | UnresolvedIncidentsRequestFailureAction
  | IncidentSeverityRequestAction
  | IncidentSeverityRequestSuccessAction
  | IncidentSeverityRequestFailureAction
  | IncidentStatusRequestAction
  | IncidentStatusRequestSuccessAction
  | IncidentStatusRequestFailureAction
  | Top5IncidentsRequestAction
  | Top5IncidentsRequestSuccessAction
  | Top5IncidentsRequestFailureAction
  | AllIncidentsRequestAction
  | AllIncidentsRequestSuccessAction
  | AllIncidentsRequestFailureAction
  | SetAllIncidentsFiltersAction
  | SetAllIncidentsSortFieldAction
  | SetAllIncidentsPerPageAction
  | SetAllIncidentsCurrentPageAction
  | DetailedIncidentRequestAction
  | DetailedIncidentRequestSuccessAction
  | DetailedIncidentRequestFailureAction
  | SetDetailedIncidentLogPerPageAction
  | SetDetailedIncidentLogCurrentPageAction
  | SendEmailRequestAction
  | SendEmailRequestSuccessAction
  | SendEmailRequestFailedAction;
